import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import {
	FormControl,
	FormLabel,
	InputBase,
	MenuItem,
	Select,
	Switch,
	SwitchProps,
	Typography,
	styled,
	useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { useLayoutEffect, useState } from "react";
import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import moment from "moment";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		fontSize: 16,
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}));

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));
const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("First Name is required")
		.min(2, "The First name must be at least 2 characters"),
	lastName: yup
		.string()
		.required("Last Name is required")
		.min(2, "The Last name must be at least 2 characters"),
	email: yup
		.string()
		.email("You must enter a valid email")
		.required("You must enter a email"),
	phoneNumber: yup.string().optional(),
	address1: yup.string().optional(),
	address2: yup.string().optional(),
	city: yup.string().optional(),
	stateId: yup.string().optional(),
	zipcode: yup.string().optional(),
	notifyByEmail: yup.boolean().optional(),
	notifyBySms: yup.boolean().optional(),
	stripeCustomerId: yup.string().optional(),
	isActive: yup.boolean().optional(),
});

/**
 * The analytics dashboard app.
 */
function AdminEditUserProfileForm({ user, states, onAdd, loading }) {
	var optionList = [
		{ id: 0, name: "Disabled" },
		{ id: 1, name: "Enabled" },
	];
	let zero: number = 0;

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			firstName: user.firstName ?? "",
			lastName: user.lastName ?? "",
			email: user.email ?? "",
			phoneNumber: user.phoneNumber ?? "",
			address1: user.address1 ?? "",
			address2: user.address2 ?? "",
			city: user.city ?? "",
			stateId: user.stateId ?? "",
			zipcode: user.zipcode ?? "",
			notifyByEmail: user.notifyByEmail ?? false,
			notifyBySms: user.notifyBySms ?? false,
			stripeCustomerId: user.stripeCustomerId ?? null,
			isActive: user.isActive,
		},
		resolver: yupResolver(schema),
	});

	const [notifyByEmail, setNotifyByEmail] = useState(
		user.notifyByEmail ?? false
	);
	const [notifyBySms, setNotifyBySms] = useState(user.notifyBySms ?? false);
	const theme = useTheme();
	const { control, watch, trigger, reset, handleSubmit, formState, setValue } =
		methods;
	const { errors, isValid, dirtyFields } = formState;

	const isActive = watch("isActive");

	useLayoutEffect(() => {
		console.log(errors);
	}, [errors]);

	function onSubmit(data: any) {
		onAdd(data);
	}

	return (
		<>
			{loading && <FuseSplashScreen />}
			<div className="flex  w-full items-center px-8 py-12 md:px-16 border-b">
				<div className="flex flex-col md:flex-row w-full justify-between my-12 mx-8">
					<div className="flex items-center space-x-8">
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
						>
							<Typography
								className="flex items-center sm:mb-12"
								component={Link}
								role="button"
								to="/admin-dashboard"
								color="inherit"
							>
								<FuseSvgIcon size={20}>
									{theme.direction === "ltr"
										? "heroicons-outline:arrow-sm-left"
										: "heroicons-outline:arrow-sm-right"}
								</FuseSvgIcon>
								<Typography className="flex mx-4 font-700 text-2xl">
									<span className="capitalize">
										{user.firstName} {user.lastName}
									</span>
									's User Profile{" "}
								</Typography>
							</Typography>
							<Typography
								className="flex items-center sm:mb-12"
								role="button"
								color="inherit"
							>
								<span className="flex mx-4 font-400 text-lg">
									Account created {moment(user.createDate).format("LLLL")}
								</span>
							</Typography>
						</motion.div>
					</div>
					<div>
						<FieldLabel>User Enabled</FieldLabel>
						<BootstrapSwitch
							checked={isActive}
							onChange={(ev) => {
								setValue("isActive", ev.target.checked);
							}}
							aria-label="enabled or disabled?"
						/>
					</div>
				</div>
			</div>
			<form
				name="registerForm"
				noValidate
				className="mt-32 flex w-full flex-col justify-center"
				id="signupform-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex w-full flex-row flex-wrap">
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>First Name</FieldLabel>
						<Controller
							name="firstName"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									aria-label="First Name"
									autoFocus
									id="firstName"
									variant="outlined"
									fullWidth
									error={!!errors.firstName}
									helperText={errors?.firstName?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Last Name</FieldLabel>
						<Controller
							name="lastName"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="lastName"
									variant="outlined"
									fullWidth
									error={!!errors.lastName}
									helperText={errors?.lastName?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Email</FieldLabel>
						<Controller
							name="email"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="email"
									variant="outlined"
									fullWidth
									error={!!errors.email}
									helperText={errors?.email?.message as string}
								/>
							)}
						/>
					</div>

					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Phone</FieldLabel>
						<Controller
							name="phoneNumber"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="phone"
									variant="outlined"
									fullWidth
									error={!!errors.phoneNumber}
									helperText={errors?.phoneNumber?.message as string}
								/>
							)}
						/>
					</div>
					{/* <div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Account Status</FieldLabel>
						<Controller
							name="accountStatus"
							control={control}
							defaultValue={user.isActive ? 1 : 0}
							render={({ field: _field }) => (
								<FormControl className="min-w-80">
									<Select
										{..._field}
										variant="outlined"
										size="small"
										className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
										sx={{ border: "solid 2px black" }}
									>
										<MenuItem key={0} value="0">
											Disabled
										</MenuItem>
										<MenuItem key={1} value="1">
											Enabled
										</MenuItem>
									</Select>
								</FormControl>
							)}
						/>
					</div> */}

					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Address</FieldLabel>
						<Controller
							name="address1"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="address1"
									variant="outlined"
									fullWidth
									error={!!errors.address1}
									helperText={errors?.address1?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Address 2</FieldLabel>
						<Controller
							name="address2"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="address2"
									variant="outlined"
									fullWidth
									error={!!errors.address2}
									helperText={errors?.address2?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>City</FieldLabel>
						<Controller
							name="city"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="city"
									variant="outlined"
									fullWidth
									error={!!errors.city}
									helperText={errors?.city?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>State</FieldLabel>
						<Controller
							name="stateId"
							control={control}
							render={({ field: { onChange, value } }) => (
								<BootstrapAutoComplete
									// onChange={onChange}
									onChange={(
										event: any,
										newValue: { id: string; name: string }
									) => {
										onChange(newValue?.id);
									}}
									value={value || null}
									autoFocus
									options={states}
									groupBy={(option: any) => option?.country}
									getOptionLabel={(option: any) =>
										isNaN(option)
											? option
												? option?.name
												: ""
											: states.find((c) => c?.id == option)?.name ?? ""
									}
									renderInput={(params: any) => {
										return (
											<TextField
												{...params}
												autoFocus
												id="stateId"
												variant="standard"
												fullWidth
												InputProps={{
													...params.InputProps,
													disableUnderline: true,
												}}
												error={!!errors.stateId}
												helperText={errors?.stateId?.message as string}
											/>
										);
									}}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Zip</FieldLabel>
						<Controller
							name="zipcode"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="zipcode"
									variant="outlined"
									fullWidth
									error={!!errors.zipcode}
									helperText={errors?.zipcode?.message as string}
								/>
							)}
						/>
					</div>
					<div className="flex w-full flex-col md:w-1/2 px-7">
						<FieldLabel>Stripe Customer ID</FieldLabel>
						<Controller
							name="stripeCustomerId"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
									sx={{ border: "solid 2px black" }}
									required
									autoFocus
									id="stripeCustomerId"
									variant="outlined"
									fullWidth
									error={!!errors.stripeCustomerId}
									helperText={errors?.stripeCustomerId?.message as string}
								/>
							)}
						/>
					</div>
				</div>
				<div className="flex sm:justify-end justify-center mt-[20px]">
					<CustomPrimaryButton
						onClick={() => {
							trigger();
						}}
					>
						Save Profile
					</CustomPrimaryButton>
				</div>
			</form>
		</>
	);
}

export default AdminEditUserProfileForm;
