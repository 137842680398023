import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";

/**
 * The user navbar header.
 */
function UserNavbarHeader() {
	const user = useSelector(selectUser);

	return (
		<div className="user relative flex flex-col items-start justify-start p-16 pb-14 shadow-0">
			<div className="mb-40 flex items-start justify-start pl-[12px]">
				<img
					className="h-40px w-[110px] flex mx-auto"
					src="assets/img/checkout-logo.svg"
					alt="logo"
				/>
			</div>
		</div>
	);
}

export default UserNavbarHeader;
