import { useEffect, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import AddPetAppHeader from "./AddAlertAppHeader";
import _ from "../../../../@lodash/@lodash";
import { IPetModel } from "@fuse/models";
import petService from "@fuse/services/petService";
import { IApiResponse } from "@fuse/models/user.models";
import AddAlertPetHeaderAfterSelection from "./AddAlertPetHeaderAfterSelection";
import utilityService from "@fuse/services/utilityService";
import AddPetAlertForm from "./components/AddPetAlertForm";
import moment from "moment";
import { PetAlertType } from "./types/PetAlert.type";
import { EmailStats } from "./types/EmailStats.type";
import { Card } from "@mui/material";
import { useParams } from "react-router";

function AddAlertByPet() {
	const param = useParams();
	const dispatch = useAppDispatch();
	const [breeds, setBreeds] = useState([]);
	const [allSpecies, setSpecies] = useState([]);
	const [allBrands, setBrands] = useState([]);
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);
	const [petAlert, setPetAlert] = useState<PetAlertType>({} as PetAlertType);
	const [emailStates, setEmailStates] = useState<EmailStats>({} as EmailStats);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const petId = param.id;
		getPetById(petId);
	}, []);

	useEffect(() => {
		if (pet.id) {
			utilityService
				.getStaticData()
				.then((res: any) => {
					if (res.result) {
						setSpecies(res.result.species);
						setBrands(res.result.brands);
					}
				})
				.catch((error) => {
					console.log(error);
				});

			utilityService
				.getPetBreedsByPetId(pet.id)
				.then((res: any) => {
					if (res.result) {
						setBreeds(res.result);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [pet]);

	async function getPetById(id) {
		const data = (await petService.getPetById(id)) as IApiResponse;
		const pet = data.result as any;

		if (pet.image) {
			pet.imageUrl = `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
		}

		if (pet.dob) {
			pet.dob = new Date(pet.dob);
		}
		if (pet.lastRabiesVaccine) {
			pet.lastRabiesVaccine = new Date(pet.lastRabiesVaccine);
		}

		setPet(pet);
	}

	function onUpdate(data) {
		data.id = pet.id;
		if (!data.dob) {
			data.dob = null;
		} else {
			data.dob = moment(data.dob).format("YYYY-MM-DD");
		}

		if (!data.lastRabiesVaccine) {
			data.lastRabiesVaccine = null;
		} else {
			data.lastRabiesVaccine = moment(data.lastRabiesVaccine).format(
				"YYYY-MM-DD"
			);
		}

		data.breeds = [];

		if (data.petBreedList?.length > 0) {
			data.petBreedList.forEach((f) => {
				data.breeds.push({
					id: f,
					name: f,
				});
			});
		}

		data.image = data.imageUrl?.indexOf(";") > 0 ? data.imageUrl : pet.image;
		setLoading(true);
		petService.updatePet(pet.id, data).then((res: IPetModel) => {
			if (res.id) {
				const missingDate = moment(data.missingDate);
				let body = {
					name: pet.name,
					petId: pet.id,
					missingDate: missingDate,
					zipCode: data.zipCode,
					status: "NEW",
				};
				petService.sendMailFromPetAlert(body).then((resp: IApiResponse) => {
					if (resp.succeeded) {
						window.location.href = resp.result.location;
					}
				});
			} else {
				setLoading(false);
			}
		});
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<motion.div>
						<AddAlertPetHeaderAfterSelection pet={pet} />
					</motion.div>

					{pet.id && !petAlert?.isPaid && (
						<AddPetAlertForm
							pet={pet}
							allBrands={allBrands}
							breeds={breeds}
							allSpecies={allSpecies}
							loading={loading}
							onUpdate={onUpdate}
						/>
					)}
				</Card>
			</div>
		</div>
	);
}

export default AddAlertByPet;
