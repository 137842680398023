import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { selectUser, selectUserData } from "app/store/user/userSlice";
import SignoutConfirmationDialog from "@fuse/core/SignoutConfirmationDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";

function UserMenu() {
	const user = useSelector(selectUser);
	const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);
	const [showSignoutConfirmation, setShowSignoutConfimation] = useState(false);
	const [hasSubscription, setHasSubscription] = useState(false);
	const [dashboardUrl, setDashboardUrl] = useState("");
	const [count, setCount] = useState(0);

	useLayoutEffect(() => {
		if (user) {
			const { haveActivePets, haveSubscriptions, subscriptionUrl } = user.data;

			if (haveSubscriptions) {
				setHasSubscription(true);
				setDashboardUrl(subscriptionUrl);
			}
		}
	}, [user]);

	const userMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
		setShowSignoutConfimation(false);
	};
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	function handleSignout() {
		setUserMenu(null);
		setShowSignoutConfimation(true);
	}

	return (
		<>
			<Button
				className="min-h-40 min-w-40 p-0 md:px-16 md:py-6 user-menu-header"
				onClick={userMenuClick}
				color="inherit"
			>
				{!isMobile ? (
					<div className="mx-4 flex-col items-end flex gap-6">
						<Typography
							component="span"
							className="font-robotoslab flex text-14px sm:text-16px leading-16px sm:leading-16px font-700"
						>
							{user.data?.displayName}
						</Typography>
						<Typography className="text-peeva-black text-14px sm:text-16px leading-16px sm:leading-16px font-400">
							{user.data.email}
						</Typography>
					</div>
				) : (
					<div></div>
				)}

				{user.data.photoUrl ? (
					<Avatar
						className="md:mx-4 w-[44px] h-[44px]"
						alt="user photo"
						src={user.data.photoUrl}
					/>
				) : (
					<Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
				)}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					paper: "py-8",
				}}
			>
				<>
					<MenuItem
						component={Link}
						to="/client/profile"
						onClick={userMenuClose}
						role="button"
						className="flex w-full items-center"
					>
						<ListItemIcon className="min-w-40 text-16px font-700 leading-22px">
							<FuseSvgIcon className="text-peeva-black">
								heroicons-outline:user-circle
							</FuseSvgIcon>
						</ListItemIcon>

						<Typography
							variant="caption"
							display="block"
							gutterBottom
							className="text-16px font-700 leading-22px text-peeva-black"
						>
							Profile
						</Typography>
					</MenuItem>
					{hasSubscription && (
						<MenuItem
							component={Link}
							to={`${process.env.VITE_APP_API_BASE_URL}utilities/stripe-dashboard?uid=${user.id}`}
							target="_blank"
							onClick={userMenuClose}
							role="button"
							className="flex w-full items-center"
						>
							<ListItemIcon className="min-w-40 text-16px font-700 leading-22px">
								<SettingsIcon className="text-peeva-black" />
							</ListItemIcon>

							<Typography
								variant="caption"
								display="block"
								gutterBottom
								className="text-16px font-700 leading-22px text-peeva-black"
							>
								Subscription
							</Typography>
						</MenuItem>
					)}
					<MenuItem
					className="top-user-menu"
						onClick={() => {
							handleSignout();
						}}
					>
						<ListItemIcon className="min-w-40 text-16px font-700 leading-22px">
							<LogoutIcon className="text-peeva-black" />
						</ListItemIcon>

						<Typography
							variant="caption"
							display="block"
							gutterBottom
							className="text-16px font-700 leading-22px text-peeva-black"
						>
							Log Out
						</Typography>
					</MenuItem>
				</>
			</Popover>
			{showSignoutConfirmation && (
				<SignoutConfirmationDialog callback={() => userMenuClose()} />
			)}
		</>
	);
}

export default UserMenu;
