import Typography from "@mui/material/Typography";
import { useEffect, useLayoutEffect, useState } from "react";
import _ from "@lodash";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import BackupContactDialog from "./backup-contact-dialog";
import { BackupContactType } from "../types/TeamMemberType";
import userService from "@fuse/services/userService";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { showMessage } from "app/store/fuse/messageSlice";
import { useAppDispatch } from "app/store";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";
import IconButton from '@mui/material/IconButton';
import DeleteButton from "@fuse/core/DeleteButton/DeleteButton";

function getFormattedPhoneNum(input) {
	let output = "(";
	input.replace(
		/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
		function (match, g1, g2, g3) {
			if (g1.length) {
				output += g1;
				if (g1.length == 3) {
					output += ")";
					if (g2.length) {
						output += " " + g2;
						if (g2.length == 3) {
							output += " - ";
							if (g3.length) {
								output += g3;
							}
						}
					}
				}
			}
		}
	);
	return output;
}

function BackupContacts({ contacts, fetchContacts }) {
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [contact, setContact] = useState<BackupContactType>(
		{} as BackupContactType
	);


	function handleContactClick(elem) {
		setContact(elem);
		setOpen(true);
	}

	function handleDialogClose(res) {
		if (res.name) {
			userService
				.saveBackupContact(res)
				.then((res: any) => {
					if (res) {
						setOpen(false);
						setContact({} as BackupContactType);
						fetchContacts();
						let message: string = "Record saved successfully";
						dispatch(showMessage({ message }));
					}
				})
				.catch((error) => { });
		} else {
			setOpen(false);
			setContact({} as BackupContactType);
		}
	}

	function handleDeleteClick(id) {
		//console.log(elem.id);
		userService
			.deleteBackupContact(id)
			.then((res: any) => {
				if (res) {
					setOpen(false);
					setContact({} as BackupContactType);
					fetchContacts();
					let message: string = "Record deleted successfully";
					dispatch(showMessage({ message }));
				}
			})
			.catch((error) => { });
	}

	return (
		<>
			<div className="flex flex-col sm:flex-row flex-wrap gap-[20px]">
				<div className="flex w-full flex-col sm:flex-row flex-wrap gap-[20px]">
					<div className="flex w-full sm:w-3/4 flex-1">
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
						>
							<CardHeading
								heading="Backup Contacts"
								customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
								subHeading="By adding backup contacts, you certify that you have their permission for Peeva to contact them in the event you cannot be contacted. "
								customClassSubHeading="text-16px font-400 tracking-tight leading-22px"
							/>
						</motion.div>
					</div>
					<div className="flex w-full md:w-1/4 justify-center sm:justify-end items-center cursor-pointer">
						<CustomOutlinePrimaryButton onClick={() => setOpen(true)}>
							Add a contact
						</CustomOutlinePrimaryButton>
					</div>
				</div>
				<div className="flex w-full flex-col gap-[20px]">
					<div className="grid grid-cols-2 w-full gap-4">
						<div className="font-700 text-14px md:text-16px leading-19px md:leading-22px">
							Contact Name
						</div>
						<div className="font-700 text-14px md:text-16px leading-19px md:leading-22px">
							Phone Number
						</div>
					</div>

					{contacts.map((m) => {
						return (
							<motion.div
								key={m.id}
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<div
									className="grid grid-cols-2 w-full gap-4"

								>
									<div className="font-400 text-14px leading-21px">
										<DeleteButton recordId={m.id} onDelete={handleDeleteClick} />
										<IconButton color="primary" aria-label="add" size="small" onClick={() => handleContactClick(m)}>
											<EditOutlinedIcon className="mr-3" />
										</IconButton>  {m.name}

									</div>
									<div className="font-400 text-12px md:text-14px leading-16px md:leading-21px">
										{getFormattedPhoneNum(m.phoneNumber)}
									</div>
								</div>
							</motion.div>
						);
					})}

					{open && (
						<BackupContactDialog
							info={contact}
							handleClose={handleDialogClose}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default BackupContacts;
