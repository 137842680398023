import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import VetProfileCard from "./VetProfileCard";

function VetSearchListing({ service, search }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (search) {
            let request = {
                query: search,
                fields: ["All"],
                type: ["veterinary_care"],
            };

            service.textSearch(request, (result: []) => {
                let results = [];
                if (result.length > 0) {
                    result.forEach((item: any) => {
                        let d = {} as any;
                        d.reference = item.reference;
                        d.place_id = item.place_id;
                        if (item.name) results.push(d);
                    });
                }
                setData(results);
            });
        } else {
            setData([]);
        }
    }, [service, search]);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: 40 },
        show: { opacity: 1, y: 0 },
    };

    return (
        <motion.div>
            {data.length > 0 &&
                <motion.div variants={container} initial="hidden" animate="show" className="flex flex-col gap-[20px]">
                    {data.map((hospital) => (
                        <motion.div key={hospital.reference} className="w-fulll">
                            <VetProfileCard data={hospital} service={service} />
                        </motion.div>
                    ))}
                </motion.div>
            }
        </motion.div>
    )
}

export default VetSearchListing