import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import { FuseRouteConfigsType, FuseRoutesType } from "@fuse/utils/FuseUtils";
import DashboardsConfigs from "../main/client/clientsConfigs";
import AdminDashboardAppConfig from "../main/admin/adminConfigs";
import SignUpFlowConfig from "../main/auth/SignUpFlow/UserFlow/SignUpFlowConfig";
import PetRegisterFlowConfig from "../main/auth/SignUpFlow/PetFlow/PetRegisterFlowConfig";
import Error404PageNew from "../main/common/404/Error404PageNew";
import SignOutConfig from "../main/auth/SignOut/SignOutConfig";
import SignInConfig from "../main/auth/SignIn/SignInConfig";
import ForgotPasswordConfig from "../main/auth/forgot-password/ForgotPasswordConfig";
import ResetPasswordConfig from "../main/auth/reset-password/ResetPasswordConfig";
import EditProfileAppConfig from "../main/client/editprofile/EditProfileAppConfig";
import CommonLookupConfig from "../main/common/CommonLookupConfig";

const routeConfigs: FuseRouteConfigsType = [
	SignOutConfig,
	SignInConfig, 
	SignUpFlowConfig,
    PetRegisterFlowConfig,
	ForgotPasswordConfig,
	ResetPasswordConfig,
	CommonLookupConfig,
	...DashboardsConfigs
];

const adminRouteCongits: FuseRouteConfigsType = [
	...AdminDashboardAppConfig,
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	...FuseUtils.generateRoutesFromConfigs(adminRouteCongits, ['admin']),
	{
		path: "/",
		element: <Navigate to="/client" />,
		auth: settingsConfig.defaultAuth
	},
	{
		path: "loading",
		element: <FuseLoading />
	},
	 {
	 	path: "404",
		element: <Error404PageNew />
	},
	{
		path: "*",
		element: <Navigate to="sign-up" />
	}
];

export default routes;
