import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField, styled } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLayoutEffect, useState } from "react";
import { IPetModel } from "@fuse/models";
import FieldLabel from "../editprofile/components/FieldLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Navigate, useNavigate } from "react-router";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const schema = yup.object().shape({
	petId: yup.string().optional(),
});

function AddAlertAppHeader({ pets, selectPet }) {
	const navigate = useNavigate();
	const defaultValues = {
		petId: "",
	};

	const [pet, setPet] = useState({} as IPetModel);

	const { control, formState, trigger, handleSubmit, setError } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	useLayoutEffect(() => {
		console.log(pets);
	}, [pets]);

	function setSelectedPet() {
		const selPet = pet as any;
		if (selPet.dob) {
			selPet.dob = new Date(pet.dob);
		}
		if (selPet.lastRabiesVaccine) {
			selPet.lastRabiesVaccine = new Date(selPet.lastRabiesVaccine);
		}
	//	selectPet(selPet);
		navigate(`/client/add-alert/${pet.id}`);
	}

	return (
		<>
			<div className="flex w-full container bg-white">
				<div className="flex flex-col flex-auto leading-20px content-start">
					<Typography className="font-quicksand text-18px md:text-20px font-700 leading-28px">
						Lost Pet Alert
					</Typography>
					<p className="font-robotoslab text-16px font-400 leading-24px">
						Send a lost pet alert with your pet's picture, microchip ID, and
						your contact information to every pet care professional within a
						50-mile radius of your pet's last known location, with one click!
					</p>
				</div>
			</div>
			<div className="flex flex-col md:flex-row flex-auto pt-[28px] content-start">
				<div className="flex w-full md:w-3/4 flex-col leading-28px">
					<FieldLabel>Select your lost pet</FieldLabel>
					<Controller
						name="petId"
						control={control}
						render={({ field: { onChange, value } }) => (
							<BootstrapAutoComplete
								onChange={(event: any, newValue: IPetModel) => {
									onChange(newValue?.id);
									if (newValue?.id) {
										setPet(newValue);
									}
								}}
								value={value || null}
								autoFocus
								options={pets}
								className="mt-8"
								getOptionLabel={(option: any) =>
									typeof option == "object"
										? option
											? option?.name
											: ""
										: pets.find((c) => c?.id == option)?.name ?? ""
								}
								renderOption={(props, option: any, index) => {
									return (
									  <li {...props} key={option.id}>
									   
										{option.name}
									  </li>
									);
								  }}
								renderInput={(params: any) => {
									return (
										<TextField
											{...params}
											key={params.id}
											autoFocus
											placeholder="Please Select"
											id="petId"
											variant="standard"
											fullWidth
											
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
											}}
											error={!!errors.petId}
											helperText={errors?.petId?.message as string}
										/>
									);
								}}
							/>
						)}
					/>
				</div>				
				<div className="flex flex-col items-center justify-end leading-loose w-full md:w-1/4">
					<CustomPrimaryButton
						disabled={!pet?.id}
						onClick={() => setSelectedPet()}
					>
						Select and continue
					</CustomPrimaryButton>
				</div>
			</div>
		</>
	);
}

export default AddAlertAppHeader;
