import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import UserType, { UserSignupType } from "app/store/user/UserType";
import { PartialDeep } from "type-fest";
import { IApiResponse, IUserLoginResponse } from "@fuse/models/user.models";

class UserService {
	getCurrentUser = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Users/current-user`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getLoginUser = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Users/token-login`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						let data = response.data.result.user as UserType;
						if (data.data.photoUrl) {
							data.data.photoUrl = `${process.env.VITE_APP_API_BASE_URL}Users/user-images/${data.data.photoUrl}`;
						}

						resolve(data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updateUser = (data, id) =>
		new Promise((resolve, reject) => {
			axios
				.put(`${process.env.VITE_APP_API_BASE_URL}Users/${id}`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	changeUserPassword = (data, id) =>
		new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.VITE_APP_API_BASE_URL}Users/ChangeUserPassword`,
					data
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getBackupContacts = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Users/backup-contacts`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	saveBackupContact = (contact) =>
		new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.VITE_APP_API_BASE_URL}Users/backup-contact`,
					contact
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	deleteBackupContact = (id) =>
		new Promise((resolve, reject) => {
			axios
				.delete(`${process.env.VITE_APP_API_BASE_URL}Users/BackupContact/${id}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	searchUsersByAdmin = (data) =>
		new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.VITE_APP_API_BASE_URL}Users/SearchUsersByAdmin`,
					data
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
}

const instance = new UserService();

export default instance;
