import { useEffect, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import AddPetAppHeader from "./AddAlertAppHeader";
import _ from "../../../../@lodash/@lodash";
import { IPetModel } from "@fuse/models";
import petService from "@fuse/services/petService";
import { IApiResponse } from "@fuse/models/user.models";
import utilityService from "@fuse/services/utilityService";
import moment from "moment";
import { Card } from "@mui/material";
import AddAlertSearchForAnalytics from "./AddAlertSearchForAnalytics";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function AddAlertApp() {
	const dispatch = useAppDispatch();
	const [pets, setPets] = useState<IPetModel[]>([]);
	const [paidPets, setPaidPets] = useState<IPetModel[]>([]);
	const [breeds, setBreeds] = useState([]);
	const [allSpecies, setSpecies] = useState([]);
	const [allBrands, setBrands] = useState([]);
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);

	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getUserPets();
	}, []);

	useEffect(() => {
		if (pet.id) {
			utilityService
				.getStaticData()
				.then((res: any) => {
					if (res.result) {
						setSpecies(res.result.species);
						setBrands(res.result.brands);
					}
				})
				.catch((error) => {
					console.log(error);
				});

			utilityService
				.getPetBreedsByPetId(pet.id)
				.then((res: any) => {
					if (res.result) {
						setBreeds(res.result);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [pet]);

	async function getUserPets() {
		const data = (await petService.getUserPetList()) as IApiResponse;
		const pets = data.result as Array<any>;
		setPets(pets.filter((f) => f.status !== "paid"));
		setPaidPets(pets.filter((f) => f.status === "paid"));
	}

	function refreshPets() {
		getUserPets();
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<motion.div>
						<AddPetAppHeader pets={pets} selectPet={setPet} />
					</motion.div>					
				</Card>
			</div>
			{paidPets.length > 0 && (
			<div className={`flex flex-col gap-[20px] pt-20`}>
				<Card className="p-[28px] overflow-hidden">
					<motion.div>
						<AddAlertSearchForAnalytics
							pets={paidPets}
							refreshPets={refreshPets}
						/>
					</motion.div>
				</Card>
			</div>
			)}
		</div>
	);
}

export default AddAlertApp;
