import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

/**
 * The Error404Page component renders a custom 404 error page.
 */
function Error404PageNew() {
	const navigate = useNavigate();
	return (
		<div className="flex flex-1 flex-col items-center p-16 bg-white">
			<div className="w-full max-w-3xl text-center">
				<motion.div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
				>
					<div className="mx-auto max-w-2xl md:pt-52 leading-26px">
						<img
							className="h-40px w-[110px] flex mx-auto"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
						<img
							className="h-70px w-[60px] flex mx-auto mt-36"
							src="assets/img/ErrorIcon.svg"
							alt="logo"
						/>
					</div>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: 40 }}
					animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
				>
					<Typography className="mt-14 sm:text-32px text-28px font-700 text-center sm:leading-38px leading-32 tracking-tight text-black">
						Sorry! Page not found.
					</Typography>
					<Typography className="mt-14 sm:text-16px text-16px font-400 text-center sm:leading-18px leading-18 tracking-tight text-black">
						Looks like you accessed a page that doesn’t exist.
					</Typography>
				</motion.div>
				<Button
					variant="contained"
					color="secondary"
					className="mt-16 sm:h-58 h-48px rounded-16 w-[200px] bg-peeva-blue border hover:bg-peeva-blue-110 transition-colors duration-200 sm:text-16px font-700 sm:leading-18px text-14px leading-16px"
					aria-label="Go back"
					onClick={() => navigate('/')}
					type="submit"
					size="large"
					id="signuphome-submit-button"
				>
					Go back
				</Button>
			</div>
		</div>
	);
}

export default Error404PageNew;
