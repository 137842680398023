import { Button } from "@mui/material";

function CustomPrimaryButton(props) {
	return (
		<Button
			variant="contained"
			color="secondary"
			className="w-full sm:w-auto sm:h-56px h-48px rounded-[32px] bg-peeva-yellow border hover:bg-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
			aria-label={props.children}
			type="submit"
			size="large"
			disabled={props.disabled || false}
			onClick={props.onClick}
		>
			{props.children} {props.disabled}
		</Button>
	);
}

export default CustomPrimaryButton;
