import { useParams } from "react-router";
import React, { useLayoutEffect } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import { red } from "@mui/material/colors";

const AnyReactComponent = ({ text, lat, lng }) => (
	<div>
		<RoomIcon className="text-red-500" />
	</div>
);

function VetMap({ lat, lng, vetName = "" }) {
	const key = process.env.VITE_APP_GOOGLE;
	const defaultProps = {
		center: {
			lat: lat,
			lng: lng,
		},
		zoom: 9,
	};

	return (
		<div className="h-full w-full">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: key,
				}}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
			>
				<AnyReactComponent lat={lat} lng={lng} text={vetName} />
			</GoogleMapReact>
		</div>
	);
}

export default VetMap;
