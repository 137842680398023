import lazyWithReducer from 'app/store/lazyWithReducer';
import authRoles from '../../auth/authRoles';
import AdminDashboardApp from './AdminDashboardApp';

/**
 * The ProjectDashboardApp configuration.
 */
const AdminDashboardAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: 'admin-dashboard',
			element: <AdminDashboardApp />,
			exact: true
		},
		
	]
};

export default AdminDashboardAppConfig;
