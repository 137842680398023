import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import FieldLabel from '../FieldLabel/FieldLabel';

interface TransferConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleTransfer: (email: string) => void;
  dialogText?: string;
  email: string;
  confirmEmail: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onConfirmEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emailError: string;
}

const TransferConfirmationModal: React.FC<TransferConfirmationModalProps> = ({
  open,
  handleClose,
  handleTransfer,
  dialogText,
  email,
  confirmEmail,
  onEmailChange,
  onConfirmEmailChange,
}) => {

  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (open) {
      email = '';
      confirmEmail = '';
      setEmailError('');
    }
  }, [open]);

  const handleOk = () => {
    if (email === confirmEmail) {
      handleTransfer(email);
      handleClose();
    } else {
      setEmailError("Emails don't match");
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Transfer</DialogTitle>
      <DialogContent>
        <div>
        <FieldLabel>What email address should the pet be transferred to?</FieldLabel>
        <TextField
          margin="dense"
          id="email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={onEmailChange}
          sx={{ mb: 2, '& .MuiOutlinedInput-root': { minWidth:500, borderColor: 'black', borderWidth: 2 ,borderRadius:24 , opacity:1 } }}
        />
        <FieldLabel>Please re-enter the email address of the recipient to confirm</FieldLabel>
        <TextField
          margin="dense"
          id="confirm-email"
          type="email"
          fullWidth
          variant="outlined"
          value={confirmEmail}
          onChange={onConfirmEmailChange}
          sx={{ '& .MuiOutlinedInput-root': { minWidth:500, borderColor: 'black', borderWidth: 2 ,borderRadius:24 , opacity:1 } }}
        />
        {/* Error message displayed below the text fields */}
        {emailError && (
          <Typography variant="body2" color="error">
            {emailError}
          </Typography>
        )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" color="error" onClick={handleOk}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferConfirmationModal;
