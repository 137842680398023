import AddAlertApp from './AddAlertApp';
import AddAlertByPet from './AddAlertByPet';
import PetAlertSent from './PetAlertSent';
/**
 * The analytics dashboard app config.
 */
const AddAlertAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: 'client/add-alert',
			element: <AddAlertApp />
		},
		{
			path: 'client/add-alert/:id',
			element: <AddAlertByPet />
		},
		{
			path: 'client/add-alert/:id/sent',
			element: <PetAlertSent />
		}
	]
};

export default AddAlertAppConfig;
