import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import utilityService from "@fuse/services/utilityService";
import { Avatar, Box, Switch, SwitchProps, styled } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import CardHeading from "../../../../../@fuse/core/CardHeading/CardHeading";
import FieldLabel from "../../../../../@fuse/core/FieldLabel/FieldLabel";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("First Name is required")
		.min(2, "The First name must be at least 2 characters"),
	lastName: yup
		.string()
		.required("Last Name is required")
		.min(2, "The Last name must be at least 2 characters"),
	email: yup
		.string()
		.email("You must enter a valid email")
		.required("You must enter a email"),
	phoneNumber: yup.string().optional(),
	address1: yup.string().optional(),
	address2: yup.string().optional(),
	city: yup.string().optional(),
	stateId: yup.string().optional(),
	zipcode: yup.string().optional(),
	photoUrl: yup.string().optional(),
	notifyByEmail: yup.boolean().optional(),
	notifyBySms: yup.boolean().optional(),
});

function EditProfileForm({ user, onAdd, loader }) {
	const [drag, setDrag] = useState(false);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			firstName: user.firstName ?? "",
			lastName: user.lastName ?? "",
			phoneNumber: user.phoneNumber ?? "",
			address1: user.address1 ?? "",
			address2: user.address2 ?? "",
			city: user.city ?? "",
			zipcode: user.zipcode ?? "",
			notifyByEmail: user.notifyByEmail ?? false,
			notifyBySms: user.notifyBySms ?? false,
			stateId: user.stateId ?? null,
			email: user.email ?? "",
			photoUrl: user.photoUrl ?? "",
		},
		resolver: yupResolver(schema),
	});

	const [notifyByEmail, setNotifyByEmail] = useState(
		user.notifyByEmail ?? false
	);
	const [notifyBySms, setNotifyBySms] = useState(user.notifyBySms ?? false);

	const { control, watch, trigger, reset, handleSubmit, formState, setValue } =
		methods;
	const { errors, isValid, dirtyFields } = formState;
	const [states, setStates] = useState<any[]>([]);

	useLayoutEffect(() => {
		getStates();
	}, []);

	useEffect(() => {
		console.log("loader", loader);
	}, [loader]);

	const dragOver = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragEnter = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragLeave = (e) => {
		e.preventDefault();
		setDrag(false);
	};

	const fileDrop = (e, onChange) => {
		e.preventDefault();
		setDrag(false);
		const files = e.dataTransfer.files;
		if (files.length) {
			if (files.length > 1) {
				//TODO: Show Alert here
			}
			handleFile(files[0], onChange);
		}
	};

	const handleFile = (file, onChange) => {
		if (!file.type.startsWith("image/")) {
			alert("Only image files can be uploaded");
			return;
		}
		const reader: FileReader = new FileReader();
		reader.onload = () => {
			if (typeof reader.result === "string") {
				onChange(`data:${file.type};base64,${btoa(reader.result)}`);
			} else {
				// TODO: SHOW ERROR
			}
		};
		reader.onerror = () => {
			// TODO: SHOW ERROR
		};
		reader.readAsBinaryString(file);
	};

	async function getStates() {
		const res = (await utilityService.getStates()) as any;
		if (res.result) {
			setStates(res.result);
		}
	}
	function onSubmit(data: any) {
		onAdd(data);
	}

	return (
		<>
			{loader && <FuseSplashScreen />}
			<div className="flex flex-col gap-[20px]">
				<CardHeading
					heading="Your Profile"
					subHeading="To update your information, simply edit any form field and click the “save profile” button."
				/>
				<form
					name="registerForm"
					noValidate
					className="flex w-full flex-col justify-center overflow-y-scroll"
					id="signupform-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="flex sm:flex-row flex-col w-full gap-[20px]">
						<div className="flex sm:justify-start align-start justify-center">
							<Controller
								control={control}
								name="photoUrl"
								render={({ field: { onChange, value } }) => (
									<Box
										className="relative flex items-center justify-center w-[200px] h-[200px] rounded-xl overflow-hidden border-dashed border-[2px] border-black"
										onDragOver={dragOver}
										onDragEnter={dragEnter}
										onDragLeave={dragLeave}
										onDrop={(e) => fileDrop(e, onChange)}
									>
										{/* <div className="absolute inset-0 bg-black bg-opacity-50 z-10" /> */}
										<div className="absolute inset-0 flex items-center justify-center z-20 w-full h-full cursor-pointer p-[40px_20px]">
											<div>
												<label
													htmlFor="pet-image"
													className="flex cursor-pointer"
												>
													<input
														accept="image/*"
														className="hidden"
														id="pet-image"
														type="file"
														onChange={async (e) => {
															function readFileAsync() {
																return new Promise((resolve, reject) => {
																	const file = e?.target?.files?.[0];
																	if (!file) {
																		return;
																	}
																	const reader: FileReader = new FileReader();

																	reader.onload = () => {
																		if (typeof reader.result === "string") {
																			resolve(
																				`data:${file.type};base64,${btoa(
																					reader.result
																				)}`
																			);
																		} else {
																			reject(
																				new Error(
																					"File reading did not result in a string."
																				)
																			);
																		}
																	};
																	reader.onerror = reject;
																	reader.readAsBinaryString(file);
																});
															}
															const newImage = await readFileAsync();
															onChange(newImage);
														}}
													/>
													<FuseSvgIcon className="text-white opacity-10 text-opacity-0 w-full h-full">
														heroicons-outline:camera
													</FuseSvgIcon>
												</label>
											</div>
											<div className="absolute top-10 right-6 cursor-pointer">
												<CloseIcon
													className="text-32"
													onClick={() => {
														onChange("");
													}}
												/>
											</div>
										</div>
										<Avatar
											sx={{
												backgroundColor: "background.default",
												color: "text.secondary",
												borderRadius: 0,
											}}
											className="object-cover w-full h-full text-center text-[12px] font-700 leading-[24px]"
											src={value}
											alt={yup?.Schema?.name}
										>
											Drag and drop or click to upload your profile photo
										</Avatar>
									</Box>
								)}
							/>
						</div>
						<div className="flex-col w-full gap-32">
							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col sm:w-1/2 sm:gap-[8px] gap-[4px]">
									<FieldLabel>First Name</FieldLabel>
									<Controller
										name="firstName"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												aria-label="First Name"
												autoFocus
												id="firstName"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.firstName}
												helperText={errors?.firstName?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:w-1/2 sm:gap-[8px] gap-[4px]">
									<FieldLabel>Last Name</FieldLabel>
									<Controller
										name="lastName"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="lastName"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.lastName}
												helperText={errors?.lastName?.message as string}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full gap-[20px] mt-[20px]">
								<div className="flex w-full flex-col sm:w-1/2 sm:gap-[8px] gap-[4px]">
									<FieldLabel>Address</FieldLabel>
									<Controller
										name="address1"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="address1"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.address1}
												helperText={errors?.address1?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:w-1/2 sm:gap-[8px] gap-[4px]">
									<FieldLabel>Address 2 (optional)</FieldLabel>
									<Controller
										name="address2"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="address2"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.address2}
												helperText={errors?.address2?.message as string}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full gap-[20px] mt-[20px]">
								<div className="flex w-full flex-col sm:w-1/3 sm:gap-[8px] gap-[4px]">
									<FieldLabel>City</FieldLabel>
									<Controller
										name="city"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="city"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.city}
												helperText={errors?.city?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:w-1/3 sm:gap-[8px] gap-[4px]">
									<FieldLabel>State</FieldLabel>
									<Controller
										name="stateId"
										control={control}
										defaultValue=""
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
												}}
												value={value || null}
												autoFocus
												options={states}
												groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													isNaN(option)
														? option
															? option?.name
															: ""
														: states.find((c) => c?.id == option)?.name ?? ""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															id="stateId"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.stateId}
															helperText={errors?.stateId?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:w-1/3 sm:gap-[8px] gap-[4px]">
									<FieldLabel>Zip Code</FieldLabel>
									<Controller
										name="zipcode"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="zipcode"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.zipcode}
												helperText={errors?.zipcode?.message as string}
											/>
										)}
									/>
								</div>
							</div>

							{/* <div className="flex w-full gap-32"></div> */}
							<div className="flex w-full flex-col sm:flex-row gap-[20px] mt-[20px]">
								<div className="flex flex-col w-full basis-6/12 sm:gap-[8px] gap-[4px]">
									<FieldLabel>Email Address</FieldLabel>
									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="email"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												disabled={!notifyByEmail}
												error={!!errors.email}
												helperText={errors?.email?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex lg:w-1/3 w-full md:pl-[18.8rem] items-center basis-6/12 ">
									<Controller
										name="notifyByEmail"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full">
												<FieldLabel>Email Notifications</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
														setNotifyByEmail(ev.target.checked);
													}}
													aria-label="Notify by Email"
												/>
											</div>
										)}
									/>
								</div>
							</div>
							<div className="flex w-full flex-col sm:flex-row gap-[20px] mt-[20px]">
								<div className="flex flex-col w-full basis-6/12">
									<FieldLabel>Phone Number</FieldLabel>
									<Controller
										name="phoneNumber"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px md:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												autoFocus
												id="phoneNumber"
												variant="outlined"
												disabled={!notifyBySms}
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.phoneNumber}
												helperText={errors?.phoneNumber?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex lg:w-1/3 w-full md:pl-[18.8rem] items-center basis-6/12">
									<Controller
										name="notifyBySms"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full">
												<FieldLabel>SMS Notifications</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
														setNotifyBySms(ev.target.checked);
													}}
													aria-label="Send SMS notifications"
												/>
											</div>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="flex sm:justify-end justify-center mt-[20px]">
						{!loader && (
							<CustomPrimaryButton
								onClick={() => {
									trigger();
								}}
							>
								Save Profile
							</CustomPrimaryButton>
						)}

						{loader && (
							<CustomPrimaryButton onClick={() => {}}>
								Saving Profile ...
							</CustomPrimaryButton>
						)}
					</div>
				</form>
			</div>
		</>
	);
}

export default EditProfileForm;
