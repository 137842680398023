import _ from "../../../../../../../@lodash/@lodash";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { useLayoutEffect, useState } from "react";
import utilityService from "@fuse/services/utilityService";
import adminService from "@fuse/services/adminService";
import petService from "@fuse/services/petService";
import { useAppDispatch } from "app/store";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { IPetModel } from "@fuse/models";
import HTMLReactParser from "html-react-parser";
import { Card, Typography, useTheme } from "@mui/material";
import { showMessage } from "app/store/fuse/messageSlice";
import AdminEditPetProfileForm from "./AdminEditPetProfileForm";
import { PetUpdateType } from "src/app/main/client/addpet/types/PetUpdateType";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function AdminEditPetApp() {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const theme = useTheme();
	const navigate = useNavigate();
	const param = useParams();
	const id = param.id;
	const dispatch = useAppDispatch();
	const [breeds, setBreeds] = useState([]);
	const [allSpecies, setSpecies] = useState([]);
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useLayoutEffect(() => {
		utilityService
			.getPetBreedsByPetId(id)
			.then((res: any) => {
				if (res.result) {
					setBreeds(res.result);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		utilityService
			.getStaticData()
			.then((res: any) => {
				if (res.result) {
					setSpecies(res.result.species);
					//setBrands(res.result.brands);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		adminService
			.getPetById(id)
			.then((res: any) => {
				if (res.result) {
					const pet = res.result;
					if (pet.image) {
						pet.imageUrl = `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
					}

					if (pet.dob) {
						pet.dob = new Date(pet.dob);
					}
					if (pet.lastRabiesVaccine) {
						pet.lastRabiesVaccine = new Date(pet.lastRabiesVaccine);
					}

					setPet(res.result);
				}
			})
			.catch((error: string[]) => {
				setError(error.join("<br />"));
				setTimeout(() => {
					setError("");
				}, 10000);
			});
	}, []);

	function handlePetDelete() {
		adminService
			.removePet(id)
			.then((res: any) => {
				if (res.succeeded) {
					dispatch(showMessage({message: "Pet deleted", variant: 'info'}));
					navigate("/admin");
				}
			})
			.catch((error: string[]) => {
				setError(error.join("<br />"));
				setTimeout(() => {
					setError("");
				}, 10000);
			});
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<div className="p-[28px]">
					{error && (
						<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
							<Alert variant="filled" severity="error" onClose={() => {}}>
								<div>{HTMLReactParser(error)}</div>
							</Alert>
						</Stack>
					)}
					{pet.id && allSpecies?.length > 0 && (
						<AdminEditPetProfileForm
							pet={pet}
							allSpecies={allSpecies}
							id={id}
							loading={loading}
							handleDelete={handlePetDelete}
						/>
					)}
				</div>
			</div>
		</div>
		
	);
}

export default AdminEditPetApp;
