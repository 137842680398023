import _ from "@lodash";
import Paper from "@mui/material/Paper";
import { useLayoutEffect, useState } from "react";
import { Card, Input, Typography } from "@mui/material";
import commonService from "../commonService";
import { useDispatch } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";

function HealthCheckPage() {
	const dispatch = useDispatch();
	const [healthStatus, setHealthStatus] = useState("loading");
	const [loading, setLoading] = useState(false);

	useLayoutEffect(() => {
		getHealthStatus();
	}, []);

	function getHealthStatus() {
		setLoading(true);
		commonService
			.getHealthStatus()
			.then((res: any) => {
				setLoading(false);
				setHealthStatus(res.result);
			})
			.catch((error) => {
				setHealthStatus("loading");
			});
	}

	return (
		<div className="flex w-full h-full flex1 flex-col">
			<div className="flex flex-row min-w-screen pt-20 h-56 relative">
				<div className="flex items-start justify-center w-full h-56px border-b-1">
					<img
						className="h-40px w-[110px] flex mx-auto"
						src="assets/img/checkout-logo.svg"
						alt="logo"
					/>
				</div>
			</div>
			<div className="flex flex-wrap items-center mx-auto p-24 sm:p-32 w-[60%] mt-40">
				<div className="flex flex-col w-full pb-10">
						<Typography
							component="div"
							className="font-robotoslab my-10 text-20px font-400 leading-14px text-peeva-black md:text-left text-center"
						>
							Health Status
						</Typography>
					<Paper className="flex h-44 w-full items-center rounded-16 px-16 shadow">
						<Input
							disableUnderline
							fullWidth
							className="font-quicksand font-400 text-16px"
							readOnly
							value={healthStatus}
						/>
					</Paper>
				</div>
			</div>
			{loading && <FuseLoading className="full-screen" />}
		</div>
	);
}

export default HealthCheckPage;
