import { FuseRouteConfigsType } from "@fuse/utils/FuseUtils";
import AdminDashboardAppConfig from "./dashboard/AdminDashboardAppConfig";
import SendEmailAppConfig from "./sendemail/SendEmailAppConfig";
import AdminSearchAppConfig from "./adminsearch/AdminSearchAppConfig";
import AdminSitesAppConfig from "./adminsites/AdminSitesAppConfig";
import BulkPetUploadAppConfig from "./bulkpetupload/BulkPetUploadAppConfig";

/**
 * Dashboards
 */
const adminConfigs: FuseRouteConfigsType = [
	AdminDashboardAppConfig,
	SendEmailAppConfig,
	AdminSearchAppConfig,
	AdminSitesAppConfig,
	BulkPetUploadAppConfig
];

export default adminConfigs;
