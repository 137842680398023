import { Button } from "@mui/material"

function CustomOutlinePrimaryButton(props) {
    return (
        <Button
            variant="outlined"
            color="secondary"
            className="sm:h-56px h-48px rounded-[32px] bg-white  border-peeva-yellow border-[3px] hover:border-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
            aria-label={props.children}
            type={props.type || 'submit'}
            size="large"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    )
}

export default CustomOutlinePrimaryButton