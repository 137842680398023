import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { memo } from "react";
import UserNavbarHeader from "../../../../shared-components/UserNavbarHeader";
import Navigation from "../../../../shared-components/Navigation";
import { Typography } from "@mui/material";
import { Link, SvgIcon } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	"& ::-webkit-scrollbar-thumb": {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === "light"
				? "rgba(0, 0, 0, 0.24)"
				: "rgba(255, 255, 255, 0.24)"
		}`,
	},
	"& ::-webkit-scrollbar-thumb:active": {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === "light"
				? "rgba(0, 0, 0, 0.37)"
				: "rgba(255, 255, 255, 0.37)"
		}`,
	},
}));

const StyledContent = styled(FuseScrollbars)(() => ({
	overscrollBehavior: "contain",
	overflowX: "hidden",
	overflowY: "auto",
	WebkitOverflowScrolling: "touch",
	backgroundRepeat: "no-repeat",
	backgroundSize: "100% 40px, 100% 10px",
	backgroundAttachment: "local, scroll",
}));

type NavbarStyle1ContentProps = {
	className?: string;
};

/**
 * The navbar style 1 content.
 */
function NavbarStyle1Content(props: NavbarStyle1ContentProps) {
	const { className = "" } = props;

	return (
		<Root
			className={clsx(
				"flex h-full flex-auto flex-col overflow-hidden",
				className
			)}
		>
			
			<StyledContent
				className="flex min-h-0 flex-1 flex-col"
				option={{ suppressScrollX: true, wheelPropagation: false }}
			>
				<UserNavbarHeader />

				<Navigation layout="vertical" />

				<div className="flex w-full flex-col items-center pl-28 py-48 leading-5">
					<Typography
						variant="body2"
						gutterBottom
						className="text-white flex w-full text-12px font-400 leading-16px mb-14"
					>
						1-833-PEEVA-CO
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						className="text-white flex w-full text-12px font-400 leading-16px"
					>
						Peeva, Inc.
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						className="text-white flex w-full text-12px font-400 leading-16px"
					>
						640 Ellicott St,
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						className="text-white flex w-full text-12px font-400 leading-16px"
					>
						Buffalo, NY 14203, USA
					</Typography>
					<Link
						href="https://peeva.co/"
						className="text-peeva-blue flex w-full text-14px font-700 leading-19px mt-14"
					>
						Home
					</Link>
					<Link
						href="https://peeva.co/blog"
						className="text-peeva-blue flex w-full text-14px font-700 leading-19px mt-14"
						role="button"
						color="inherit"
					>
						Blog
					</Link>
					<Link
						className="text-peeva-blue flex w-full text-14px font-700 leading-19px my-8"
						href="https://peeva.co/privacy"
						role="button"
						target={"_blank"}
						color="inherit"
					>
						Privacy
					</Link>
					
					<Link
						className="text-peeva-blue flex w-full text-14px font-700 leading-19px mb-14"
						href="https://peeva.co/terms-of-service"
						role="button"
						color="inherit"
					>
						Terms of Service
					</Link>
					<Typography
						variant="body2"
						gutterBottom
						className="text-white flex w-full text-12px font-400 leading-16px mt-8"
					>
						Copyright © {new Date().getFullYear()} Peeva, Inc.
					</Typography>
				</div>
			</StyledContent>
		</Root>
	);
}

export default memo(NavbarStyle1Content);
