import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface DeleteConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  dialogText?:string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ open, handleClose, handleDelete, dialogText }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText || 'Are you sure you want to delete this record?'} </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
