import React, { useEffect, useLayoutEffect, useState } from "react";
import history from "src/@history";
import { BrowserRouterProps, Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";

/**
 * The BrowserRouter component provides an interface for users to interact with the application's history.
 * It is responsible for managing the routing through the use of the `react-router-dom` library and the browser's `history` object.
 * It also integrates with `useLayoutEffect` to listen for changes to the `location` state from the browser's `history` object.
 */
function BrowserRouter(props: BrowserRouterProps) {
	const { basename, children } = props;
	const user = useSelector(selectUser);

	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	});

	const [prevState, setPrevState] = useState({
		action: null,
		location: null,
	});

	useEffect(() => {
		history.listen(setState);
	}, [history]);

	useEffect(() => {
		if (prevState.location?.pathname !== state.location.pathname) {
			setPrevState({
				action: state.action,
				location: state.location,
			});
			handleDataLayerPush();
		}
	}, [state]);

	function handleDataLayerPush() {
		try {
			const { pathname } = history.location;
			let phone = "";
			if (user.data.phoneNumber) {
				phone = formatPhoneNumber(user.data.phoneNumber);
			}

			//@ts-ignore
			window.dataLayer = window.dataLayer || [];
			// @ts-ignore
			window.dataLayer.push({
				event: "page_view",
				url: pathname,
			});

			if (user.id) {
				// @ts-ignore
				window.dataLayer.push({
					event: "user_data",
					user_id: user.id,
					user_data: {
						email: user.data.email,
						phone_number: phone,
						address: {
							first_name: user.data.firstName || "",
							last_name: user.data.lastName || "",
							street: user.data?.address1 || "",
							city: user.data.city || "",
							region: user.data.state?.abbreviation || "",
							postal_code: user.data?.zipcode || "",
							country: user.data.state?.countryAbbreviation || "",
						},
					},
				});

				console.log(history.location);

				if (window.location.href.indexOf("pet-register") > -1) {
					// @ts-ignore
					window.dataLayer.push({
						event: "user_created",
					});
				}
			}
		} catch {}
	}

	function formatPhoneNumber(phone: string) {
		phone = phone.replace(/\D/g, "");

		if (phone.length == 11 && phone.startsWith("1")) {
			phone = `+${phone}`;
		} else if (phone.length == 10) {
			phone = `+1${phone}`;
		}

		return phone;
	}

	return (
		<Router
			basename={basename}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		>
			{children}
		</Router>
	);
}

export default BrowserRouter;
