import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
	selectFuseCurrentLayoutConfig,
	selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "app/store/fuse/navbarSlice";
import { Layout1ConfigDefaultsType } from "app/theme-layouts/layout1/Layout1Config";
import NavigationShortcuts from "../../shared-components/NavigationShortcuts";
import UserMenu from "../../shared-components/UserMenu";

type ToolbarLayout1Props = {
	className?: string;
};

/**
 * The toolbar layout 1.
 */
function ToolbarLayout1(props: ToolbarLayout1Props) {
	const { className } = props;
	const config = useSelector(
		selectFuseCurrentLayoutConfig
	) as Layout1ConfigDefaultsType;
	const navbar = useSelector(selectFuseNavbar);
	const toolbarTheme = useSelector(selectToolbarTheme);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx("relative z-20 flex shadow-md", className)}
				color="default"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? toolbarTheme.palette.background.paper
							: toolbarTheme.palette.background.default,
				}}
				position="static"
			>
				<Toolbar className="min-h-48 p-0 md:min-h-64">
					<div className="flex flex-1 px-16">
						<NavigationShortcuts />
					</div>

					<div className="flex h-full items-center overflow-x-auto px-8">
						<UserMenu />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(ToolbarLayout1);
