import CardHeading from "@fuse/core/CardHeading/CardHeading";
import { IVetModel } from "@fuse/models";
import vetService from "@fuse/services/vetService";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router";
import UserVetList from "./UserVetList";

/**
 * The analytics dashboard app header.
 */

function AddVetAppHeader() {
	const [vets, setVets] = React.useState<Array<IVetModel>>([]);
	const navigate = useNavigate();

	React.useLayoutEffect(() => {

		vetService
			.getUserVets()
			.then((res: any) => {
				if (res.result) {
					setVets(res.result);
				}
			})
			.catch((error) => {
				setVets([]);
			});

	}, []);
	return (
		<div className="flex flex-col gap-[20px]">
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
			>
				<CardHeading
					heading="Your Vets"
					subHeading="Click on a vet to view their profile and contact information."
					customClassHeading="sm:text-[20px] sm:leading-[28px] font-700 text-peeva-black text-[18px] leading-[21.6px]"
					customClassSubHeading="font-400 sm:text-[16px] sm:leading-[24px] text-peeva-black text-[14px] leading-[21px]"
				/>
			</motion.div>
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
			>
				<UserVetList vets={vets} />
			</motion.div>
		</div>
	);
}

export default AddVetAppHeader;
