import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "app/store";
import {
	selectFuseCurrentSettings,
	setDefaultSettings,
} from "app/store/fuse/settingsSlice";
import _ from "@lodash";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarToggle, navbarToggleMobile } from "app/store/fuse/navbarSlice";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { FuseSettingsConfigType } from "@fuse/core/FuseSettings/FuseSettings";
import { Button } from "@mui/base";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";

type NavbarToggleButtonProps = {
	className?: string;
	children?: React.ReactNode;
};

/**
 * The navbar toggle button.
 */
function NavbarToggleButton(props: NavbarToggleButtonProps) {
	const user = useSelector(selectUser);
	const {
		className = "",
		children = (
			<FuseSvgIcon size={20} color="action" className="text-peeva-blue">
				heroicons-outline:view-list
			</FuseSvgIcon>
		),
	} = props;

	const dispatch = useAppDispatch();
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const settings: FuseSettingsConfigType = useAppSelector(
		selectFuseCurrentSettings
	);
	const { config } = settings.layout;

	return (
		<>
			<IconButton
				className={className}
				color="inherit"
				size="small"
				onClick={() => {
					if (isMobile) {
						dispatch(navbarToggleMobile());
					} else if (config?.navbar?.style === "style-2") {
						dispatch(
							setDefaultSettings(
								_.set(
									{},
									"layout.config.navbar.folded",
									!settings?.layout?.config?.navbar?.folded
								)
							)
						);
					} else {
						dispatch(navbarToggle());
					}
				}}
			>
				{children}
			</IconButton>
			<Button
				className="min-h-40 min-w-40 p-0 md:px-16 md:py-6"
				color="inherit"
			>
				{!isMobile ? (
					<div className="mx-4 flex-row flex items-center gap-16">
						<Typography
							component="span"
							className="font-robotoslab flex text-14px sm:text-16px leading-16px sm:leading-16px font-700"
						>

						</Typography>
						<Typography
							component="span"
							className="font-robotoslab text-14px sm:text-16px leading-16px sm:leading-16px font-700"
						>
							Hello {user.data.displayName}!
						</Typography>
					</div>
				) : (
					<div></div>
				)}

			</Button>
		</>
	);
}

export default NavbarToggleButton;
