import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import validator from "validator";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLayoutEffect, useState } from "react";
import { Alert, IconButton, InputAdornment, Rating } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import jwtService from "../../services/jwtService";
import { Stack } from "@mui/system";
import { ratingColors } from "../../../../../@fuse/colors";
import HTMLReactParser from "html-react-parser";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { SignupFormType } from "../../types/plan.type";
import JwtService from "../../services";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	firstName: yup.string().required("You must enter first name"),
	lastName: yup
		.string()
		.max(255, "Last Name can't be more than 255 characters long")
		.required("You must enter last name"),
	email: yup
		.string()
		.email("You must enter a valid email")
		.required("You must enter a email"),
	phoneNumber: yup
		.string()
		.max(19, "Phone Number can't be more than 19 characters long")
		.test("is-phone-valid", "Invalid phone number.", (value) => {
			if (value && value.trim() !== "") {
				return validatePhoneNumber(value);
			}
			return true;
		})
		.required("You must enter a Mobile Phone"),
	password: yup
		.string()
		.max(255, "Password can't be more than 255 characters long")
		.required("Please enter your password.")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		),
});
const CustomPrimaryButton = ({
	bgClass,
	roundedClass,
	children,
	disabled = false,
	onClick,
}) => {
	const bgClassName = bgClass ? bgClass : "bg-peeva-yellow";
	const roundedClassName = roundedClass ? roundedClass : "rounded-[32px]";
	const className = `w-full sm:w-auto sm:h-56px h-48px ${roundedClassName} ${bgClassName} border py-10 sm:text-16px font-700 sm:leading-[18.75px] text-14px leading-16px text-peeva-black`;
	return (
		<Button
			variant="contained"
			className={className}
			aria-label={children}
			type="submit"
			size="large"
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

const validatePhoneNumber = (number) => {
	const isValidPhoneNumber = validator.isMobilePhone(number);
	return isValidPhoneNumber;
};

/**
 * The sign up page.
 */
function SignUpForm() {
	const defaultValues = {
		firstName: getStoredForm().firstName,
		lastName: getStoredForm().lastName,
		email: getStoredForm().email,
		phoneNumber: getStoredForm().phoneNumber,
		password: getStoredForm().password,
	};
	const queryParams = new URLSearchParams(location.search);
	const navigate = useNavigate();
	const qEmail = queryParams.get("email");
	if (qEmail) {
		defaultValues.email = decodeURIComponent(qEmail);
	}
	const { control, formState, trigger, handleSubmit, setError } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [customError, setCustomError] = useState("");

	const { isValid, dirtyFields, errors } = formState;

	useLayoutEffect(() => {
		JwtService.clearHistory();
		if (!qEmail) {
			navigate("/sign-up");
		}
		onBackFromPetRegister();
	}, [qEmail]);

	async function onBackFromPetRegister() {
		if (sessionStorage.getItem("signup")) {
			const res = await JwtService.deactivateUser();
			setLoading(false);
			if (res.succeeded) {
				const session = sessionStorage.getItem("signup");
				if (session) {
					const { email } = JSON.parse(session);
					navigate(`/sign-up-form?email=${email}`);
				}
			}
			sessionStorage.removeItem("signup");
		}
	}

	function onSubmit({
		firstName,
		lastName,
		email,
		phoneNumber,
		password,
	}: typeof defaultValues) {
		setLoading(true);
		const userObj = {
			firstName,
			lastName,
			email,
			phoneNumber,
			password,
		};

		sessionStorage.setItem("signup", JSON.stringify(userObj));

		jwtService
			.createUser(userObj)
			.then((res) => {
				setLoading(false);
				navigate("/pet-register");
				// No need to do anything, registered user data will be set at app/auth/AuthContext
			})
			.catch(
				(
					_errors: {
						type: "email" | "password" | `root.${string}` | "root";
						message: string;
					}[]
				) => {
					setLoading(false);
					setCustomError(_errors[0].message);

					setTimeout(() => {
						setCustomError("");
					}, 5000);
				}
			);
	}

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	function getStoredForm(): SignupFormType {
		if (sessionStorage.getItem("signup")) {
			const form = JSON.parse(
				sessionStorage.getItem("signup")
			) as SignupFormType;

			return form;
		}
		return {} as SignupFormType;
	}


	return (
		<>
			<div className="flex flex-col min-w-0 min-h-screen justify-center bg-white relative">
				<div className="flex flex-row min-w-screen pt-20 h-56 border-after border-after-46 relative">
					<div
						className="flex items-center pl-10 w-1/4 relative cursor-pointer border-b-3 h-56px"
						onClick={() => navigate("/sign-up")}
					>
						<ArrowBackIosIcon className="sm:text-16px text-14px font-700 leading-18px text-peeva-black-110" />{" "}
						<Typography className="sm:text-16px font-700 sm:leading-18px text-peeva-black-110 text-14px leading-16px">
							{" "}
							Back
						</Typography>
					</div>
					<div className="flex items-start justify-center w-2/4 h-56px border-b-1">
						<img
							className="h-40px w-[110px] flex mx-auto"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
					</div>
					<div className="flex items-start w-1/4 h-56px border-b-1"></div>
				</div>
				<div className="h-full w-full pt-64 px-16 py-8 justify-center sm:p-48 md:flex md:h-full md:p-64">
					<div className="mx-auto max-w-xl sm:mx-0 sm:w-480 w-320 relative">
						{customError && (
							<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
								<Alert variant="filled" severity="error" onClose={() => {}}>
									<div>{HTMLReactParser(customError)}</div>
								</Alert>
							</Stack>
						)}
						{loading && <FuseSplashScreen />}
						<form
							name="registerForm"
							noValidate
							className="mt-32 flex w-full flex-col justify-center"
							id="signupform-form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<div className="flex gap-10">
								<Controller
									name="firstName"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											label="First name"
											autoFocus
											autoComplete="off"
											type="name"
											error={!!errors.firstName}
											helperText={errors?.firstName?.message}
											required
											fullWidth
											inputProps={{ maxLength: 255 }}
											InputLabelProps={{
												style: {
													lineHeight: "4rem",
												},
											}}
										/>
									)}
								/>
								<Controller
									name="lastName"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											label="Last name"
											type="name"
											autoComplete="off"
											error={!!errors.lastName}
											helperText={errors?.lastName?.message}
											required
											fullWidth
											inputProps={{ maxLength: 255 }}
											InputLabelProps={{
												style: {
													lineHeight: "4rem",
												},
											}}
										/>
									)}
								/>
							</div>

							<Controller
								name="phoneNumber"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
										label="Mobile Phone"
										autoComplete="off"
										type="name"
										error={!!errors.phoneNumber}
										helperText={errors?.phoneNumber?.message}
										required
										fullWidth
										inputProps={{ maxLength: 255 }}
										InputLabelProps={{
											style: {
												lineHeight: "4rem",
											},
										}}
									/>
								)}
							/>
							<Typography component="div" className="mb-24 text-12px font-400 leading-14px text-peeva-black md:text-left text-center">
								Used to instantly notify you of your pet's location when their
								microchip is scanned
							</Typography>

							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
										label="Create Password"
										type={showPassword ? "text" : "password"}
										error={!!errors.password}
										helperText={errors?.password?.message}
										required
										autoComplete="off"
										fullWidth
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleShowPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
										}}
										InputLabelProps={{
											style: {
												lineHeight: "4rem",
											},
										}}
									/>
								)}
							/>
							<CustomPrimaryButton
								bgClass="bg-peeva-blue hover:bg-peeva-blue-110 transition-colors duration-200 text-white"
								roundedClass="rounded-[16px]"
								onClick={() => {
									trigger();
								}}
							>
								Continue to pet info
							</CustomPrimaryButton>

							<Typography component="div" className="mt-12 text-12px font-400 leading-14px text-peeva-black-120 md:text-left text-center">
								Peeva cares about your privacy. Your data will only be used to
								process your order and support your experience, as described in
								our
								<a
									href="https://peeva.co/privacy"
									className="!underline pl-2"
									role="button"
									target="_blank"
								>
									Privacy Policy
								</a>
								. Peeva will never sell, trade, or transfer your personal data
								to third-parties.
							</Typography>
							<div className="grid grid-cols-1 mt-20">
								<Rating
									name="read-only"
									value={5}
									readOnly
									size="large"
									className="flex justify-center color-red-900 my-8"
									sx={{
										"& .MuiRating-iconFilled": {
											color: ratingColors.primary,
										},
									}}
								/>
								<Typography component="div" className="text-black text-12px font-700 leading-18px block mt-12 md:text-left text-center">
									“Thank you sooooo much for reuniting our doggie back to us. We
									are so happy and grateful she was in good care and safe. What
									a blessing to have her safe at home 🙏💕”
								</Typography>
								<Typography component="div" className="text-black block text-center text-12px font-400 leading-16px mt-6">
									- Lelannie D
								</Typography>
							</div>
							<div className="flex justify-center gap-2 mt-32">
								<img className="flex" src="assets/img/secure.svg" alt="logo" />
								<img className="flex" src="assets/img/stripe.svg" alt="logo" />
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* second half */}
			<div className="bg-peeva-blue flex justify-between flex-col h-[100vh] md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 relative sm:h-auto sm:shadow sm:w-auto max-h-[100vh]">
				<div className="relative pt-5 w-full md:p-64 p-32 sm:p-48 z-99">
					<div className="tracking-tight text-gray-400 text-20px font-700 leading-28px">
						<Rating
							name="read-only"
							value={5}
							readOnly
							className="flex pb-24 sm:pb-12"
							sx={{
								"& .MuiRating-iconFilled": {
									color: ratingColors.default,
								},
							}}
						/>
						<Typography component="div" className="text-black my-8 sm:text-20px font-700 sm:leading-28px text-14px leading-20px">
							“Thank you sooooo much for reuniting our doggie back to us. We are
							so happy and grateful she was in good care and safe. What a
							blessing to have her safe at home 🙏💕”
						</Typography>
						<Typography  component="div" className="text-black flex mt-20 sm:text-16px font-400 sm:leading-22px text-14px leading-20px">
							- Lelannie D.
						</Typography>
					</div>
				</div>
				<div className="relative z-10 pb-0 w-full h-full signup-home-warapper">
					<div className="text-lg leading-6 tracking-tight text-gray-400 h-[100dvh]">
						<img
							className="w-full flex mx-auto absolute bottom-[20em]"
							src="assets/img/signupIcon4.svg"
							alt="logo"
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default SignUpForm;
