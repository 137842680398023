import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { Card } from "@mui/material";

/**
 * The modern pricing card component.
 */
function PricingCard(props: any) {
	const navigate = useNavigate();
	const {
		id = "",
		title = "",
		subtitle = "",
		price = "",
		buttonTitle = "",
		isPopular = false,
		details = "",
		className = "",
		onSelect,
	} = props;

	return (
		<Card className="p-[28px] overflow-hidden">
			{/* {isPopular && (
					<div className="absolute inset-x-0 top-20 md:top-20 -left-8 flex items-center">
						<img className="flex" src="assets/img/best-price.svg" alt="logo" />
					</div>
				)} */}

			<Typography className="font-quicksand text-18px sm:text-22px leading-22px sm:leading-26px font-700 text-peeva-black tracking-tight">
				{title}
			</Typography>

			<Typography
				className="font-quicksand my-8 text-14px sm:text-16px text-peeva-black font-400 leading-21px sm:leading-22px tracking-tight"
				color="text.secondary"
			>
				{subtitle}
			</Typography>

			<div className="flex items-baseline whitespace-nowrap mt-20 justify-start">
				<Typography className="font-quicksand text-22px sm:text-28px leading-26px sm:leading-34px font-700 text-peeva-black tracking-tight">
					${price}
				</Typography>
			</div>

			{details}

			<div className="flex w-full justify-center">
				<Button
					onClick={() => onSelect(id)}
					variant="contained"
					color="secondary"
					className="font-robotoslab w-full text-white sm:w-auto mt-32 sm:mt-16 h-48px sm:h-54px rounded-full bg-peeva-blue border hover:bg-peeva-blue-110 transition-colors duration-200 py-[12px] sm:py-[16px] px-[24px] sm:px-[32px] sm:text-16px text-14 font-700 sm:leading-22px leading-16px float-right"
					aria-label="Continue to payment"
					type="submit"
					size="large"
				>
					{buttonTitle}
				</Button>
			</div>
		</Card>
	);
}

export default PricingCard;
