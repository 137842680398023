import axios, { AxiosResponse } from "axios";
import { IApiResponse } from "@fuse/models/user.models";

class AdminService {
	searchPetChip = (chip: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}admin/pet-by-microchip?chip=${chip}`
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getPetUser = (petId: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}admin/pet-user/${petId}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updateUser = (data, id) =>
		new Promise((resolve, reject) => {
			axios
				.put(
					`${process.env.VITE_APP_API_BASE_URL}admin/update-user/${id}`,
					data
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updatePet = (id, data) =>
		new Promise((resolve, reject) => {
			axios
				.put(`${process.env.VITE_APP_API_BASE_URL}admin/update-pet/${id}`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
		transferPet = (id, data) =>
			new Promise((resolve, reject) => {
				axios
					.post(`${process.env.VITE_APP_API_BASE_URL}admin/transfer-pet/${id}`, data)
					.then((response: AxiosResponse<IApiResponse>) => {
						if (response.data.succeeded) {
							resolve(response.data);
						} else if (response.data.errors) {
							reject(response.data.errors);
						} else {
							reject(response.data);
						}
					});
			});
	getPetById = (id: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}admin/user-pet/${id}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	removePet = (id: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}admin/remove-pet/${id}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	searchStripeTransactions = (searchKey: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}admin/stripe-transactions/?key=${searchKey}`
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
		bulkPetUpload = (formData) =>
			new Promise((resolve, reject) => {
				axios
				.post(`${process.env.VITE_APP_API_BASE_URL}admin/bulk-pet-upload`, formData, {
					headers: {
					  'Content-Type': 'multipart/form-data'
					}
				  })
					.then((response: AxiosResponse<IApiResponse>) => {
						if (response.data.succeeded) {
							resolve(response.data.result);
						} else if (response.data.errors) {
							reject(response.data.errors);
						} else {
							reject(response.data);
						}
					});
			});
}
const instance = new AdminService();

export default instance;
