import lazyWithReducer from "app/store/lazyWithReducer";
import AddPetApp from "./components/AddPet/AddPetApp";
import UpdatePetProfileApp from "./components/UpdatePetProfile/UpdatePetProfileApp";
import CheckoutPetApp from "./components/Checkout/CheckoutPetApp";
import AddPetWithPetList from "./components/AddPet/AddPetWithPetList";

/**
 * The analytics dashboard app config.
 */
const PetAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: "client/pet",
			children: [
				{
					path: '',
					element: <AddPetWithPetList />,
				},
				{
					path: 'add',
					element: <AddPetApp />,
				},
				{
					path: "register",
					element: <AddPetApp />,
				},
				{
					path: "checkout/:id",
					element: <CheckoutPetApp />,
				},
				{
					path: "update/:id",
					element: <UpdatePetProfileApp />,
				},
			],
		},
	],
};

export default PetAppConfig;
