import Button from "@mui/material/Button";
import _ from "@lodash";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useLayoutEffect, useState } from "react";
import { Card, Input } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import commonService from "../commonService";
import { IChipLookup } from "@fuse/models";
import { useDispatch } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";

function ChipLookupPage() {
	const dispatch = useDispatch();
	const [searched, setSearched] = useState(false);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [invalidChip, setInvalidChip] = useState(false);
	const [chipReult, setChipResult] = useState<IChipLookup>({} as IChipLookup);

	useLayoutEffect(() => {
		const host = location.hostname;
		const settingHost = process.env.VITE_APP_PROD_URL;
		console.log(process.env);
		if (host !== settingHost && settingHost) {
			window.location.href = `https://${settingHost}/microchip-lookup`;
		}
	}, []);

	function handleSearch() {
		setSearched(true);
		clearError();
		if (!search) return;

		setChipResult({} as IChipLookup);

		if (!(search.length == 9 || search.length == 10 || search.length === 15)) {
			setInvalidChip(true);
			return;
		}

		setLoading(true);
		commonService
			.searchChip(search)
			.then((res: any) => {
				setLoading(false);
				if (res.result.id) {
					setChipResult(res.result);
				} else {
					setChipResult({ isRegistered: false });
				}
			})
			.catch((error) => {
				setChipResult({} as IChipLookup);
			});
	}

	function clearError() {
		setSearch("");
		setInvalidChip(false);
		setChipResult({} as IChipLookup);
	}

	return (
		<div className="flex w-full h-full flex1 flex-col">
			<div className="flex flex-auto h-100 min-h-0 relative z-10 chip-lookup-wrapper">
				<img
					className="mx-auto h-[35rem]"
					src="assets/img/microchip_lookup_header.png"
					alt="logo"
				/>
				<img
					className="mx-auto w-[34em] absolute bottom-5 right-[13%] top-[11rem]"
					src="assets/img/microchip_lookup_info_1.png"
					alt="logo"
				/>
			</div>
			<div className="flex flex-wrap absolute bottom-[3%] left-[25%] z-10 items-center mx-auto p-24 sm:p-32 w-[60%]">
				<div className="flex w-full pb-10">
					<Paper className="flex h-44 w-full items-center rounded-16 px-16 shadow rounded-none">
						<Input
							placeholder="Microchip #"
							disableUnderline
							fullWidth
							className=""
							inputProps={{
								"aria-label": "Search",
							}}
							onKeyPress={(event) => {
								if (event.key === "Enter") {
									handleSearch();
								}
							}}
							value={search}
							onChange={(e) => setSearch(e.currentTarget.value)}
						/>
						{!search && (
							<FuseSvgIcon color="action">heroicons-outline:search</FuseSvgIcon>
						)}
						{search && (
							<FuseSvgIcon onClick={() => clearError()} color="action">
								heroicons-outline:x
							</FuseSvgIcon>
						)}
					</Paper>
					<Button
						className="ml-5 px-[4em] py-[1.5em] bg-[#ffa800] text-white hover:bg-[#cca800]"
						variant="contained"
						onClick={() => handleSearch()}
						disabled={!search}
					>
						Search
					</Button>
				</div>

				{!searched && (
					<div className="flex w-full">
						<Link
							className="font-semibold text-blue-700"
							href="https://peeva.co/how-to-find-your-pets-microchip-id"
							underline="hover"
						>
							I don't know my pet's chip number.
						</Link>
					</div>
				)}

				{invalidChip && (
					<div className="flex w-full">
						<Card
							variant="outlined"
							className="p-20 border-0 bg-[#fffa] rounded-none w-full"
						>
							<p className="text-lg font-semibold text-center">
								A Microchip Number is 9, 10 or 15 digits long.
							</p>
						</Card>
					</div>
				)}

				{chipReult.isRegistered && (
					<div className="flex w-full">
						<Card
							variant="outlined"
							className="p-20 border-0 bg-[#fffa] rounded-none"
						>
							<h1 className="font-bold pb-14 text-center">
								This microchip is registered!
							</h1>
							<p className="text-lg font-semibold">
								This pet is enrolled with Peeva, Please call
								833.PEEVA.CO/833.733.8226 EXT. 703 To be immediately connected
								with a live telephone support representative.
							</p>
						</Card>
					</div>
				)}
				{chipReult.isRegistered == false && (
					<div className="flex w-full">
						<Card
							variant="outlined"
							className="p-20 border-0 bg-[#fffa] rounded-none"
						>
							<h1 className="font-bold pb-14 text-center">
								This microchip is not registered.
							</h1>
							<p className="text-lg font-semibold">
								A microchip can only work if it's enrolled with a pet recovery
								service that provides an actively searched registry and a team
								of telephone support reps, available 24/7. Please sign up to
								register this microchip.
							</p>
							<div className="flex justify-center mt-24 text-white">
								<Link
									className="ml-5 px-[3em] py-[0.8em] bg-[#ffa800] !text-white hover:bg-[#cca800] rounded-3xl !no-underline !text-bold"
									href="/sign-up"
								>
									Signup
								</Link>
							</div>
						</Card>
					</div>
				)}
			</div>
			{loading && <FuseLoading className="full-screen" />}
		</div>
	);
}

export default ChipLookupPage;
