import _ from "../../../../../@lodash/@lodash";
import SendIcon from "@mui/icons-material/Send";
import { Button, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";

function PetAlertDetail({ pet, petAlert, states, markAsFound }) {
	return (
		<>
			<div className="flex w-full md:w-1/2 flex-wrap gap-32 py-12">
				<div className="alert-box">
					<div>Emails Sent</div>
					<SendIcon className="w-[120px] h-[117px] py-24 -rotate-45 text-peeva-blue" />
					<div className="font-quicksand text-24px md:text-28px font-400 leading-28px py-10">
						{states?.counted > 0 ? states?.counted : "[loading]"}
					</div>
				</div>

				<div className="alert-box">
					<div>Delivered</div>
					<MailIcon className="w-[120px] h-[117px] py-24 text-peeva-blue" />
					<div className="font-quicksand text-24px md:text-28px font-400 leading-28px py-10">
						{states?.delivered > 0 ? states?.delivered : "[loading]"}
					</div>
				</div>

				<div className="alert-box">
					<div>Not Seen</div>
					<VisibilityOffIcon className="w-[120px] h-[117px] py-24 text-peeva-blue" />
					<div className="font-quicksand text-24px md:text-28px font-400 leading-28px py-10">
						{(states?.delivered) > 0
							? ((states.delivered - states.opened) > 0 ?  (states.delivered - states.opened) : 0)
							: "[loading]"}
					</div>
				</div>

				<div className="alert-box">
					<div>Opened</div>
					<VisibilityIcon className="w-[120px] h-[117px] py-24 text-peeva-blue" />
					<div className="font-quicksand text-24px md:text-30px font-400 leading-28px py-10">
						{states?.opened > 0 ? states.opened : "[loading]"}
					</div>
				</div>
			</div>

			<div className="flex w-full md:w-1/2 flex-col gap-16 font-400 text-16px pt-12">
				<Typography className="font-quicksand text-18px md:text-20px font-700 leading-28px">
					Over the past 30 days, our systems have attempted to send{" "}
					{states?.counted || "[loading]"} emails for this pet. Of those,{" "}
					{states?.delivered || "[loading]"}
					were successfully delivered, and {states?.opened || "[loading]"} were
					opened.
				</Typography>

				{states?.delivered && (
					<p>
						Please be patient, statistics may take some time to load/process.
					</p>
				)}
				<p>
					<b>Note:</b> Statistics on emails sent more than 30 days ago are not
					preserved.
				</p>
				<p>
					While we are able to reliably determine how many emails are delivered,
					the number of emails that we report as being "opened" is dependent on
					each veterinary provider's email systems and email clients.
				</p>
				<p>
					Not all systems report that information - so the number "opened" will
					often reflect a number lower than the number of emails that have
					actually been opened.
				</p>

				<div className="flex w-full justify-end">
					<Button
						variant="outlined"
						color="secondary"
						className="sm:h-56px max-w-[213px] h-48px rounded-[32px] bg-white  border-peeva-yellow border-[3px] hover:border-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
						aria-label="Mark as found"
						type="button"
						size="large"
						disabled={!pet.id}
						onClick={() => markAsFound()}
					>
						Mark as found
					</Button>
				</div>
			</div>
		</>
	);
}

export default PetAlertDetail;
