import { useNavigate } from "react-router";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function GoBackCommon() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-row gap-[8px] items-center">
            <ChevronLeftIcon fontSize="large" className="cursor-pointer" onClick={() => navigate(-1)} />
            <h4 className="font-700 text-[16px] leading-[22.4px]">Go back</h4>
        </div>
    )
}

export default GoBackCommon