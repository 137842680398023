import CardHeading from '@fuse/core/CardHeading/CardHeading';
import CustomPrimaryButton from '@fuse/core/CustomPrimaryButton/CustomPrimaryButton';
import { Button, IconButton, InputAdornment, Stack, TextField, styled } from '@mui/material';
import React, { useState, ChangeEvent, useRef } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FieldLabel from '@fuse/core/FieldLabel/FieldLabel';
import adminService from "@fuse/services/adminService";
import moment from 'moment';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';

const BulkPetUploadComponent: React.FC = () => {

    const isMobile = window.innerWidth <= 600;

    const CustomTextField = styled(TextField)(({ theme }) => ({
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            borderColor: 'black',
            borderWidth: 2,
            borderRadius: 96,
            opacity: 1,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'rgba(198, 198, 198, 0.14)',
                cursor: 'pointer',
            },
        },
        cursor: 'pointer',
    }));
    const Label = styled('label')(({ }) => ({
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        minWidth: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        position: 'relative',
        left: isMobile ? (file ? '0' : '-50%') : file ? '0' : '-66%',
        width: '100%',
    }));

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const EmailInputRef = useRef<HTMLInputElement | null>(null);



    const [email, setEmail] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [loading, setLoading] = useState(false);;

    const handleTextFieldClick = () => {
        fileInputRef.current?.click();
    };
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && selectedFile.type === 'text/csv') {
            setFile(selectedFile);
            // Enable the email input field once a file is uploaded
            setEmail('');
        } else {
            alert('Please select a valid CSV file.');
        }
    };

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);

        // Basic email validation using regex
        const isValid = validateEmail(value);
        setIsValidEmail(isValid);
    };

    const handleUpload = () => {
        if (file && email) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('email', email);

            setLoading(true);
            adminService.bulkPetUpload(formData).then(res => {
                if (res) {
                    handleDownloadPassedResponse(res);
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            });
        } else {
            alert('Please select a CSV file and enter a valid email address.');
        }
    };
    const handleDownloadPassedResponse = (res) => {
        const decodedContent = atob(res.fileContents);
        const blob = new Blob([decodedContent], { type: res.contentType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = res.fileDownloadName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    const handleDownloadTemplate = () => {
        // Construct the URL for the template file
        const templateUrl = `/assets/template/pet_bulk_upload_template.csv`;

        // Use fetch API to download the file
        fetch(templateUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(new Blob([blob]));

                // Create a link element and click it to trigger the download
                const a = document.createElement('a');
                a.href = url;
                a.download = `pet_bulk_upload_template.csv`;
                document.body.appendChild(a);
                a.click();

                // Clean up: remove the temporary URL and link element
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch(error => {
                console.error('Error downloading template:', error);
                // Handle error scenarios
            });
    };

    return (
        <>
            {loading && <FuseSplashScreen />}
            <CardHeading
                heading="Bulk Pet Upload"
                subHeading="Steps: 1-Download Template >> 2-Upload csv file with required pet(s) information >> 3-Provide shelter's email >> 4-Submit."
                customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
            />

            <div className="w-full p-4 pt-8 sm:pt-8 lg:pr-0 lg:pl-0 justify-center">
                <div className="flex justify-center flex-col items-center">

                    <Stack spacing={2} direction="column" className="py-24">
                        <Button
                            variant="outlined"
                            endIcon={<FileDownloadIcon />}
                            onClick={handleDownloadTemplate}
                            className="min-w-[100%] border-black border-[2px] rounded-[96px] opacity-100 leading-[57px] min-h-[57px]"
                        >
                            Download Template
                        </Button>
                        <CustomTextField
                            margin="dense"
                            type="text"
                            fullWidth
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Label htmlFor="upload-file">
                                            <span className="flex-1 text-center mr-[16px]">{file ? `${file.name}` : 'Upload File'}</span>

                                            {!file && (
                                                <IconButton
                                                    color="primary"
                                                    aria-label="upload file"
                                                    component="span"
                                                >
                                                    <FileUploadIcon />
                                                </IconButton>)}
                                        </Label>
                                        <input
                                            ref={fileInputRef}
                                            accept=".csv"
                                            className="hidden"
                                            id="upload-file"
                                            type="file"
                                            onChange={handleFileChange}
                                        />

                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                        <div>
                            <FieldLabel>Shelter's Email Address</FieldLabel>
                            <TextField
                                margin="dense"
                                id="email"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={email}
                                onChange={handleEmailChange}
                                error={!isValidEmail} // Highlight input if email is invalid
                                helperText={!isValidEmail ? 'Please enter a valid email address' : ''}
                                disabled={!file}
                                InputProps={{
                                    sx: {
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: !file ? 'grey' : 'black',
                                        },
                                        '& .MuiInputBase-input': {
                                            color: !file ? 'grey' : 'inherit',
                                        },
                                    },
                                }}
                                sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-root': {
                                        minWidth: '100%',
                                        borderColor: !file ? 'grey' : 'black',
                                        borderWidth: 2,
                                        borderRadius: 24,
                                        opacity: 1,
                                    },
                                }}
                            />
                        </div>
                    </Stack>
                </div>
            </div>
            <div className="flex justify-center">
                <CustomPrimaryButton onClick={handleUpload} disabled={!file || !email || !isValidEmail}>
                    Submit
                </CustomPrimaryButton>
            </div>
        </>
    );
};

export default BulkPetUploadComponent;
