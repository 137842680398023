import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import _ from "@lodash";

import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import UserType from "./types/user.types";
import userService from "@fuse/services/userService";
import EditProfileForm from "./components/EditProfileForm";
import { IApiResponse } from "@fuse/models/user.models";
import UpdateUserPassword from "./components/UpdateUserPassword";
import { showMessage } from "app/store/fuse/messageSlice";
import { Card } from "@mui/material";
import { setUser } from "app/store/user/userSlice";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";


function EditProfileApp() {
	const container = {
		show: {
			transition: {
				staggerChildren: 0.06,
			},
		},
	};

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 },
	};

	const dispatch = useAppDispatch();
	const [profileUser, setProfileUser] = useState({} as UserType);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		userService
			.getCurrentUser()
			.then((res: UserType) => {
				setLoading(false);
				const user = res;
				if (user.photoUrl) {
					user.photoUrl = `${process.env.VITE_APP_API_BASE_URL}Users/user-images/${user.photoUrl}`;
				}
				setProfileUser(user);
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	}, []);

	function onAdd(data) {
		data.image =
			data.photoUrl?.indexOf(";") > 0 ? data.photoUrl : profileUser.photoUrl;
		setLoading(true);
		userService
			.updateUser(data, profileUser.id)
			.then((res: IApiResponse) => {
				
				let message: string = "Profile updated successfully";
				dispatch(showMessage({ message }));
				userService.getLoginUser().then((res: UserType) => {
					const user = res as any;
					dispatch(setUser(user));
				});
				setTimeout(() => {
					setLoading(false);
				}, 2000);
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	}

	function onUpdatePassword(data) {
		setLoading(true);
		userService
			.changeUserPassword(data, profileUser.id)
			.then((res: IApiResponse) => {
				setLoading(false);
				let message: string = "Password updated successfully";
				dispatch(showMessage({ message }));
			})
			.catch((e) => {
				setLoading(false);
			});
	}

	return (
		<div className="w-full p-[20px]">
			<>
				{loading && <FuseSplashScreen />}
				<motion.div
					className="flex flex-col gap-[20px] "
					variants={container}
					initial="hidden"
					animate="show"
				>
					<Card className="p-[28px]">
						{profileUser.id && (
							<div className="w-full">
								<EditProfileForm
									user={profileUser}
									onAdd={onAdd}
									loader={loading}
								/>
							</div>
						)}
					</Card>
					<Card className="p-[28px]">
						{profileUser.id && (
							<div className="w-full">
								<UpdateUserPassword
									user={profileUser}
									onUpdatePassword={onUpdatePassword}
									loading={loading}
								/>
							</div>
						)}
					</Card>
				</motion.div>
			</>
		</div>
	);
}

export default EditProfileApp;
