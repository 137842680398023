import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import _ from "@lodash";
import { useEffect } from "react";
import {
	Paper,
} from "@mui/material";
import JwtService from "../services";


function SignOutHome() {
	useEffect(() => {
		JwtService.logout();
	}, []);

	return (
		<>
			<div className="flex flex-col bg-white justify-center w-full h-full md:overflow-hidden hide-scrollbar">
				
				<div className="h-full w-full flex md:flex-row flex-col overflow-hidden hide-scrollbar">
					<Paper className="flex h-full w-full items-center px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-48 sm:shadow md:flex md:h-full md:w-1/2 md:items-center md:justify-end md:rounded-none md:p-64 md:shadow-none">
						<div className="mx-auto w-full max-w-320 sm:mx-0 sm:w-320">
							<img
								className="mx-auto w-48"
								src="assets/icon/android-chrome-192x192.png"
								alt="logo"
							/>

							<Typography className="mt-32 text-center text-2xl font-extrabold leading-tight tracking-tight">
								You have signed out!
							</Typography>
							<Typography
								className="mt-32 text-xl text-center font-medium"
								color="text.secondary"
							>
								<span>Go to</span>
								<Link className="ml-4 text-bold text-peeva-blue" to="/sign-in">
									Sign in
								</Link>
							</Typography>
						</div>
					</Paper>

					<div className="md:max-h-[100vh] flex justify-between flex-col h-full w-full md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 sm:shadow bg-no-repeat bg-top object-fill md:bg-center bg-contain md:bg-cover">
						<img className="object-fill" src="assets/img/signupIcon.svg" />
					</div>
				</div>
			</div>
		</>
	);
}

export default SignOutHome;
