import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField, styled } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLayoutEffect, useState } from "react";
import { IPetModel } from "@fuse/models";
import FieldLabel from "../editprofile/components/FieldLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Navigate, useNavigate } from "react-router";
import petService from "@fuse/services/petService";
import { IApiResponse } from "@fuse/models/user.models";
import { PetAlertType } from "./types/PetAlert.type";
import { EmailStats } from "./types/EmailStats.type";
import PetAlertDetail from "./components/PetAlertDetail";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const schema = yup.object().shape({
	petId: yup.string().optional(),
});

function AddAlertSearchForAnalytics({ pets, refreshPets }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const defaultValues = {
		petId: "",
	};

	const [pet, setPet] = useState({} as IPetModel);
	const [petAlert, setPetAlert] = useState<PetAlertType>({} as PetAlertType);
	const [emailStats, setEmailStats] = useState<EmailStats>({} as EmailStats);
	const { control, formState, trigger, handleSubmit, setError } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	useLayoutEffect(() => {
		if (pet.id) {
			getAlertsByPet(pet.id);
		}
	}, [pet]);

	function getAlertsByPet(petId: string) {
		petService.alertByPet(petId).then((res: IApiResponse) => {
			const petAlert = res.result as PetAlertType;
			if (petAlert) {
				petAlert.isPaid = petAlert.status?.toLocaleLowerCase() === "paid";
				setPetAlert(petAlert);
				getEmailStatus(petAlert.id);
			} else {
				dispatch(
					showMessage({ message: "No Alerts found for pet", variant: "info" })
				);

				setPetAlert({} as PetAlertType);
			}
		});
	}

	function getEmailStatus(lpaId: string) {
		petService.getEmailStats(lpaId).then((res: IApiResponse) => {
			setEmailStats(res.result);
		});
	}

	function markAsRead(){
		petService.markAsRead(pet.id).then(f => {
			refreshPets();
			dispatch(
				showMessage({ message: "Pet marked as found", variant: "success" })
			);
			setPetAlert({} as PetAlertType);
		});

	}

	return (
		<>
			<div className="flex w-full container bg-white">
				<div className="flex flex-col flex-auto leading-20px content-start">
					<Typography className="font-quicksand text-18px md:text-20px font-700 leading-28px">
						Analytics
					</Typography>
				</div>
			</div>
			<div className="flex flex-col flex-auto pt-[28px] content-start">
				<div className="flex w-full flex-col leading-28px">
					<FieldLabel>Select your lost pet</FieldLabel>
					<Controller
						name="petId"
						control={control}
						render={({ field: { onChange, value } }) => (
							<BootstrapAutoComplete
								onChange={(event: any, newValue: IPetModel) => {
									onChange(newValue?.id);
									if (newValue?.id) {
										setPet(newValue);
									}else {
										setPet({} as IPetModel);
										setEmailStats({} as EmailStats);
									}
								}}
								value={value || null}
								autoFocus
								options={pets}
								disabled={pets.length  == 0}
								className="mt-8"
								getOptionLabel={(option: any) =>
									typeof option == "object"
										? option
											? option?.name
											: ""
										: pets.find((c) => c?.id == option)?.name ?? ""
								}
								renderOption={(props, option: any, index) => {
									return (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									);
								}}
								renderInput={(params: any) => {
									return (
										<TextField
											{...params}
											key={params.id}
											autoFocus
											placeholder="Pet Name"
											id="petId"
											variant="standard"
											fullWidth
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
											}}
											error={!!errors.petId}
											helperText={errors?.petId?.message as string}
										/>
									);
								}}
							/>
						)}
					/>
				</div>
				{pet.id && (
				<div className="flex w-full flx-col md:flex-row leading-28px py-20">
					
						<PetAlertDetail
							pet={pet}
							petAlert={petAlert}
							states={emailStats}
							markAsFound={markAsRead}
						/>
					
				</div>
				)}
			</div>
		</>
	);
}

export default AddAlertSearchForAnalytics;
