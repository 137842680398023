import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { InferType } from "yup";
import * as yup from "yup";
import _ from "@lodash";
import Paper from "@mui/material/Paper";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import JwtService from "./../services/jwtService";
import { useState } from "react";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import HTMLReactParser from "html-react-parser";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import FieldLabel from "../../client/editprofile/components/FieldLabel";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	password: yup
		.string()
		.max(255, "Password can't be more than 255 characters long")
		.required("Please enter your password.")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		),
	passwordConfirm: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
	password: "",
	passwordConfirm: "",
};

/**
 * The modern reset password page.
 */
function ResetPasswordPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get("email");
	const resetCode = queryParams.get("code");
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(true);
	const [showConfirmPassword, setShowConfirmPassword] = useState(true);
	const [customError, setCustomError] = useState("");

	const { control, formState, handleSubmit, trigger, reset, setError } =
		useForm({
			mode: "onChange",
			defaultValues,
			resolver: yupResolver(schema),
		});

	const { isValid, dirtyFields, errors } = formState;

	function onSubmit({ password }: InferType<typeof schema>) {
		JwtService.setNewPasswordByToken(email, resetCode, password)
			.then((resp: any) => {
				if (resp.succeeded) {
					reset(defaultValues);
					dispatch(
						showMessage({
							message: "Password changed successfully. You can login now",
							autoHideDuration: 60000,
						})
					);
					navigate("./sign-in");
				} else {
					setCustomError(resp.errors[0]);
				}
				// No need to do anything, user data will be set at app/auth/AuthContext
			})
			.catch((e) => {
				setLoading(false);
				setCustomError(e[0].message);

				setTimeout(() => {
					setCustomError("");
				}, 3000);
			});
	}

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<>
			<div className="flex flex-col bg-white justify-center w-full h-full md:overflow-hidden hide-scrollbar">
				{loading && <FuseSplashScreen />}
				<div className="h-full w-full flex md:flex-row flex-col overflow-hidden hide-scrollbar">
					<form
						name="registerForm"
						noValidate
						className="justify-center md:basis-6/12 flex flex-col w-full h-full md:p-[40px_80px_20px_80px] p-[80px_20px_80px_20px] md:gap-[30px] gap-[20px] items-center"
						id="signuphome-form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<img
							className="h-[40px] w-[110.86px]"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
						<Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">
							Reset your password
						</Typography>
						<Typography className="font-medium">
							Create a new password for your account
						</Typography>
						<div className="flex flex-col w-full items-center">
							{customError && (
								<Stack className="pb-5" sx={{ width: "100%" }} spacing={2}>
									<Alert
										variant="filled"
										severity="error"
										onClose={() => {
											setCustomError("");
										}}
									>
										<p>{HTMLReactParser(customError)}</p>
									</Alert>
								</Stack>
							)}
							<div className="flex flex-col gap-[8px] w-full">
								<FieldLabel>Password</FieldLabel>
								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] md:h-46px text-14px md:text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											type={showPassword ? "password" : "text"}
											autoFocus
											placeholder="Enter your password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											required
											fullWidth
											variant="outlined"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleShowPassword}
															edge="end"
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
											sx={{ border: "2px solid black" }}
										/>
									)}
								/>
							</div>
							<div className="flex flex-col gap-[8px] w-full">
								<FieldLabel>Password (Confirm)</FieldLabel>
								<Controller
									name="passwordConfirm"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] md:h-46px text-14px md:text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											type={showConfirmPassword ? "password" : "text"}
											error={!!errors.passwordConfirm}
											helperText={errors?.passwordConfirm?.message}
											placeholder="Enter your password (confirm)"
											variant="outlined"
											required
											autoComplete="off"
											fullWidth
											sx={{ border: "2px solid black" }}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleShowConfirmPassword}
															edge="end"
														>
															{showConfirmPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									)}
								/>
							</div>
						</div>

						<div className="flex">
							<CustomPrimaryButton
								onClick={() => {
									trigger();
								}}
							>
								Reset your password
							</CustomPrimaryButton>
						</div>
						<div className="flex justify-center items-baseline">
							<Typography className="text-peeva-black md:text-16px text-14px font-700 md:leading-22px leading-22px">
								Return to
							</Typography>
							<Link
								className="ml-4 text-blue !no-underline md:text-16px text-14px font-700 md:leading-22px leading-22px"
								to="/sign-in"
							>
								Sign in
							</Link>
						</div>
					</form>
					{/* <div className="flex md:h-[100vh] h-full w-[640px] basis-6/12 justify-end">
						<img
							className="md:object-fill !min-w-full !w-full"
							src="assets/img/signupIcon.svg"
						/>
					</div> */}
					<div className="md:max-h-[100vh] flex justify-between flex-col h-full w-full md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 sm:shadow bg-no-repeat bg-top object-fill md:bg-center bg-contain md:bg-cover">
						<img className="object-fill" src="assets/img/signupIcon.svg" />
					</div>
				</div>
			</div>
		</>
	);
}

export default ResetPasswordPage;
