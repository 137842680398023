import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import moment from "moment";
import { ChangeEvent, useLayoutEffect, useState } from "react";
import { IApiResponse, IUserDataInfoModel } from "@fuse/models/user.models";
import FusePageCarded from "@fuse/core/FusePageCarded";
import AdminEditUserProfileForm from "./components/AdminEditUserProfileForm";
import { useThemeMediaQuery } from "@fuse/hooks";
import petService from "@fuse/services/petService";
import { IPetModel } from "@fuse/models";
import adminService from "@fuse/services/adminService";
import utilityService from "@fuse/services/utilityService";
import userService from "@fuse/services/userService";
import { showMessage } from "app/store/fuse/messageSlice";
import AdminEditPetApp from "./components/AdminEditPetApp";
import { Switch, SwitchProps, styled, useTheme, Card } from "@mui/material";

const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

/**
 * The product header.
 */
function AdminPetSearchResultView() {
	const dispatch = useAppDispatch();
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();
	const petId = params.id;
	const [loading, setLoading] = useState(false);
	const [petUser, setPetUser] = useState<IUserDataInfoModel>(
		{} as IUserDataInfoModel
	);
	const [accountSearch, setAccountSearch] = useState("");
	const [states, setStates] = useState<any[]>([]);

	useLayoutEffect(() => {
		if (petId) {
			getUserByPetId(petId);
			getStates();
		}
	}, [petId]);

	async function getUserByPetId(id) {
		const res = (await adminService.getPetUser(id)) as IUserDataInfoModel;
		if (res?.id) {
			setPetUser(res);
		}
	}

	async function getStates() {
		const res = (await utilityService.getStates()) as IApiResponse;
		if (res.result) {
			setStates(res.result);
		}
	}

	async function handleUserUpdate(data) {
		const res = (await adminService.updateUser(
			data,
			petUser.id
		)) as IApiResponse;
		if (res.result?.id) {
			dispatch(showMessage({ message: "User Updated", variant: "success" }));
		} else {
			dispatch(
				showMessage({
					message: res.errors[0] || "No record found",
					variant: "error",
				})
			);
		}
	}

	return (
		<>
			<div className="w-full p-[20px]">
				<div className="flex flex-col gap-[20px]">
					<Card className="p-[28px] overflow-hidden">
						{petUser?.id && (
							<AdminEditUserProfileForm
								user={petUser}
								states={states}
								onAdd={handleUserUpdate}
								loading={loading}
							/>
						)}

						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							className="mt-10"
						>
							<AdminEditPetApp />
						</motion.div>
					</Card>
				</div>
			</div>
		</>
	);
}

export default AdminPetSearchResultView;
