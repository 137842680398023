import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import _ from "@lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import petService from "@fuse/services/petService";
import { Link } from "react-router-dom";
import PetProfilesFooter from "./PetProfilesFooter";
import PetProfilesPartial from "./PetProfilesPartial";
import { motion } from "framer-motion";

const ITEM_HEIGHT = 48;

function PetProfilesPage() {
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});

	const navigate = useNavigate();
	const param = useParams();
	const petId = param.id;
	const [loading, setLoading] = useState(false);
	const [pet, setPet] = useState({} as any);
	const [petVets, setPetVets] = useState({} as any);

	useEffect(() => {
		getPetData();
	}, [petId]);

	async function getPetData() {
		const { pet, sites } = (await petService.getPetProfilesData(petId)) as any;
		if (pet) {
			if (pet.userPhoto) {
				pet.userPhotoUrl = `${process.env.VITE_APP_API_BASE_URL}Users/user-images/${pet.userPhoto}`;
			}

			if (pet.image) {
				pet.imageUrl = `${process.env.VITE_APP_API_BASE_URL}pet/pet-images/${pet.image}`;
			}
			setPet(pet);
			setPetVets(sites);
		}
	}

	return (
		<>
			<motion.div className="bg-white">
				{loading && <FuseLoading className="full-screen" />}
				<motion.div className="mb-[3em]">
					<div className="flex h-[64px] md:h-[100px] items-center justify-between px-16 md:px-[5em] mb-16 md:mb-14 w-full">
						<motion.div>
							<Link to="https://peeva.co">
								<img
									className="my-auto h-38px md:h-68px w-[107px] md:w-[167px]"
									src={"assets/img/checkout-logo.svg"}
									alt="logo"
								/>
							</Link>
						</motion.div>
						<motion.div>
							<Button
								variant="contained"
								className="font-quicksand min-w-[109px] md:min-w-[200px] sm:w-auto md:h-56px h-30px rounded-[36px] bg-peeva-yellow hover:bg-peeva-yellow-110 border-solid transition-colors duration-200 font-700 text-10px md:text-16px leading-14px md:leading-24px text-peeva-black"
								aria-label="Sign up"
								type="button"
								onClick={() => navigate("/client/signup")}
							>
								Register Your Pet
							</Button>
						</motion.div>
					</div>

					{placesService && pet && (
						<PetProfilesPartial
							pet={pet}
							vets={petVets || []}
							locationService={placesService}
						/>
					)}

					<PetProfilesFooter />
				</motion.div>
			</motion.div>
		</>
	);
}

export default PetProfilesPage;
