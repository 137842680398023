import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import TransferConfirmationModal from '../TransferConfirmationDialog/TransferConfirmationModal';

interface TransferButtonProps {
  
  onTransfer: (email: string) => void;
  buttonText?: string;
  buttonClass?: string;
  dialogText?: string;
}

const TransferButton: React.FC<TransferButtonProps> = ({
    
    onTransfer,
    buttonText,
    buttonClass,
    dialogText,
  }) => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [emailError, setEmailError] = useState('');
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (open) {
            setEmail('');
            setConfirmEmail('');
          setEmailError('');
        }
      }, [open]);
  
    const handleTransfer = () => {
      if (email === confirmEmail) {
        onTransfer(email);
        setOpen(false);
      } else {
        setEmailError("Emails don't match");
      }
    };
  
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
      setEmailError('');
    };
  
    const handleConfirmEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmEmail(event.target.value);
      setEmailError('');
    };
  
    return (
      <>
        <IconButton className={`${buttonClass}`} aria-label="transfer" size="small" color="error" onClick={handleOpen}>
          <SwitchAccessShortcutIcon className={`mr-3`} /> 
          {buttonText}
        </IconButton>
        <TransferConfirmationModal
          open={open}
          handleClose={handleClose}
          handleTransfer={handleTransfer}
          dialogText={dialogText}
          email={email}
          confirmEmail={confirmEmail}
          onEmailChange={handleEmailChange}
          onConfirmEmailChange={handleConfirmEmailChange}
          emailError={emailError}
        />
      </>
    );
  };

export default TransferButton;