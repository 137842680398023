import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useTheme } from '@mui/material/styles';
import CardHeading from '@fuse/core/CardHeading/CardHeading';
import UserPetList from './UserPetList';
import { IPetModel } from '@fuse/models';
import petService from '@fuse/services/petService';
import React from 'react';

/**
 * The products header.
 */
function AddPetHeader() {
	const [pets, setPets] = React.useState<Array<IPetModel>>([]);
	const navigate = useNavigate();

	React.useLayoutEffect(() => {

		petService
			.getUserPets()
			.then((res: any) => {
				if (res) {
					setPets(res);
				}
			})
			.catch((error) => {
				setPets([]);
			});

	}, []);

	return (
		<div className="flex flex-col gap-[20px]">
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
			>
				<CardHeading
					heading="Your Pets"
					subHeading="Click on a pet to manage their photo and details."
					customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
					customClassSubHeading="font-400 sm:text-[16px] sm:leading-[24px] text-peeva-black text-[14px] leading-[21px]"
				/>
			</motion.div>
			<motion.div
				initial={{ x: 20, opacity: 0 }}
				animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
			>
				<UserPetList pets={pets} />
			</motion.div>
		</div>
	);
}


export default AddPetHeader;
