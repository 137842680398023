import FusePageSimple from "@fuse/core/FusePageSimple";
import _ from "@lodash";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/store";
import DashboardAppHeader from "./AdminDashboardAppHeader";
import { Box, Card, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useNavigate } from "react-router";

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-header": {
		backgroundColor: theme.palette.background.paper,
		boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
	},
}));

/**
 * The ProjectDashboardApp page.
 */
function AdminDashboardApp() {

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<DashboardAppHeader />
				</Card>
			</div>		
		</div>
		
	);
}

export default AdminDashboardApp;
