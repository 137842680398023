import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import {
	Avatar,
	Box,
	FormControl,
	FormLabel,
	IconButton,
	IconButtonProps,
	InputAdornment,
	InputBase,
	NativeSelect,
	Stack,
	Switch,
	SwitchProps,
	styled,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CardHeading from "../../../../../@fuse/core/CardHeading/CardHeading";
import FieldLabel from "../../../../../@fuse/core/FieldLabel/FieldLabel";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

const schema = yup.object().shape({
	currentPassword: yup
		.string()
		.max(255, "Password can't be more than 255 characters long")
		.required("Please enter your current password.")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		),
	newPassword: yup
		.string()
		.max(255, "Password can't be more than 255 characters long")
		.required("Please enter new password.")
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		),
	confirmNewPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

/**
 * The analytics dashboard app.
 */
function UpdateUserPassword({ user, onUpdatePassword, loading }) {
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
		resolver: yupResolver(schema),
	});

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmNewPassword, setShowConfrimNewPassword] = useState(false);

	const { control, watch, trigger, reset, handleSubmit, formState } = methods;
	const { errors, isValid, dirtyFields } = formState;

	function onSubmit(data: any) {
		console.log(control);
		onUpdatePassword(data);
	}

	const handleShowCurrentPassword = () => {
		console.log(errors);
		setShowCurrentPassword(!showCurrentPassword);
	};
	const handleShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};
	const handleShowConfirmNewPassword = () => {
		setShowConfrimNewPassword(!showConfirmNewPassword);
	};

	return (
		<>
			{loading && <FuseSplashScreen />}
			<div className="flex flex-col gap-[20px]">
				<CardHeading heading="Your Password" subHeading="Must contain at least one uppercase, one lowercase, one digit, one special character, and be at least 6 characters long." />
				<form
					name="resetPasswordForm"
					noValidate
					className="flex w-full flex-col justify-center overflow-y-scroll"
					id="reset-password-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="flex sm:flex-row flex-col w-full gap-[20px]">
						<div className="flex w-full flex-col sm:w-1/3 gap-[8px]">
							<FieldLabel>
								Current Password
							</FieldLabel>
							<Controller
								name="currentPassword"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
										sx={{ border: "solid 2px black" }}
										type={showCurrentPassword ? "text" : "password"}
										error={!!errors.currentPassword}
										helperText={errors?.currentPassword?.message}
										variant="outlined"
										required
										autoComplete="off"
										fullWidth
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment position="end">
									// 			<IconButton
									// 				aria-label="toggle password visibility"
									// 				onClick={handleShowCurrentPassword}
									// 				edge="end"
									// 			>
									// 				{showCurrentPassword ? (
									// 					<VisibilityOff />
									// 				) : (
									// 					<Visibility />
									// 				)}
									// 			</IconButton>
									// 		</InputAdornment>
									// 	),
									// }}
									/>
								)}
							/>
						</div>
						<div className="flex w-full flex-col sm:w-1/3 gap-[8px]">
							<FieldLabel>
								New Password
							</FieldLabel>
							<Controller
								name="newPassword"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
										sx={{ border: "solid 2px black" }}
										type={showNewPassword ? "text" : "password"}
										error={!!errors.newPassword}
										helperText={errors?.newPassword?.message}
										variant="outlined"
										required
										autoComplete="off"
										fullWidth
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment position="end">
									// 			<IconButton
									// 				aria-label="toggle password visibility"
									// 				onClick={handleShowNewPassword}
									// 				edge="end"
									// 			>
									// 				{showNewPassword ? <VisibilityOff /> : <Visibility />}
									// 			</IconButton>
									// 		</InputAdornment>
									// 	),
									// }}
									/>
								)}
							/>
						</div>
						<div className="flex w-full flex-col sm:w-1/3 gap-[8px]">
							<FieldLabel>
								Confirm New Password
							</FieldLabel>
							<Controller
								name="confirmNewPassword"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
										sx={{ border: "solid 2px black" }}
										type={showConfirmNewPassword ? "text" : "password"}
										error={!!errors.confirmNewPassword}
										helperText={errors?.confirmNewPassword?.message}
										variant="outlined"
										required
										autoComplete="off"
										fullWidth
									// InputProps={{
									// 	endAdornment: (
									// 		<InputAdornment position="end">
									// 			<IconButton
									// 				aria-label="toggle password visibility"
									// 				onClick={handleShowConfirmNewPassword}
									// 				edge="end"
									// 			>
									// 				{showConfirmNewPassword ? (
									// 					<VisibilityOff />
									// 				) : (
									// 					<Visibility />
									// 				)}
									// 			</IconButton>
									// 		</InputAdornment>
									// 	),
									// }}
									/>
								)}
							/>
						</div>
					</div>
					<div className="flex sm:justify-end justify-center mt-[20px]">
						<CustomPrimaryButton onClick={() => {
							trigger();
						}}>
							Update Password
						</CustomPrimaryButton>
					</div>
				</form>
			</div>
		</>
	);
}

export default UpdateUserPassword;
