import lazyWithReducer from 'app/store/lazyWithReducer';
import DashboardApp from './DashboardApp';

/**
 * The ProjectDashboardApp configuration.
 */
const DashboardAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: 'client',
			element: <DashboardApp />
		},
		{
			path: 'client/dashboard',
			element: <DashboardApp />
		}
	]
};

export default DashboardAppConfig;
