import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import { Link, useNavigate } from "react-router-dom";
import _ from "@lodash";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Input, Paper } from "@mui/material";
import { ChangeEvent, useState } from "react";
import adminService from "@fuse/services/adminService";
import { showMessage } from "app/store/fuse/messageSlice";

function AdminSearchContentApp() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [petSearch, setPetSearch] = useState("");
	const [accountSearch, setAccountSearch] = useState("");
	const [veterinarySearch, setVeterinarySearch] = useState("");
	const [stripeSearch, setStripeSearch] = useState("");

	async function searchPet() {
		const resp = (await adminService.searchPetChip(petSearch)) as any;
		if (resp?.id) {
			navigate(`/admin/pet/${resp.peevaPetId}`);
		} else {
			dispatch(showMessage({message: "No Records found", variant: 'error'}));
		}
	}
	const searchUsersViewNavigate = () => {
		navigate(`/admin/users/${accountSearch}`);
	}
	
	const searchStripeTransactions = () =>{
		navigate(`/admin/stripe-search/${stripeSearch}`);

	}

	return (
		<div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
			<div className="flex flex-col items-start space-y-8 sm:space-y-0 w-full min-w-0 gap-58">
				<div className="flex items-start w-full flex-col">
					<motion.div
						className="flex flex-col items-start min-w-0 mx-8 sm:mx-16"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Typography className="font-robotoslab font-medium text-lg" gutterBottom>
							Search Pet's Microchip
						</Typography>
					</motion.div>

					<motion.div
						className="flex flex-row items-start min-w-0 mx-8 sm:mx-16 w-full"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							sx={{ border: "solid 2px black" }}
							className="flex items-center space-x-8 px-16 rounded-full border-1 shadow-0 w-1/2"
						>
							<FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

							<Input
								placeholder="Enter Microchip"
								className="font-robotoslab flex w-full"
								disableUnderline
								fullWidth
								name="microchipSearch"
								value={petSearch}
								inputProps={{
									"aria-label": "Search",
								}}
								onChange={(ev: ChangeEvent<HTMLInputElement>) =>
									setPetSearch(ev.target.value)
								}								
							/>
						</Paper>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className="flex items-center justify-center space-x-8 px-16 shadow-0 w-1/2"
						>
							<Button
								variant="contained"
								disabled={!petSearch}
								id="btnMicrochipSearch"
								className="font-robotoslab flex w-1/2 bg-blue-700 hover:bg-blue-800 text-white"
								onClick={() => searchPet()}
							>
								Search
							</Button>
						</Paper>
					</motion.div>
				</div>

				<div className="flex items-start w-full flex-col mt-48">
					<motion.div
						className="flex flex-col items-start min-w-0 mx-8 sm:mx-16"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Typography className="font-robotoslab font-medium text-lg" gutterBottom>
							Search Peeva Accounts
						</Typography>
					</motion.div>

					<motion.div
						className="flex flex-row items-start min-w-0 mx-8 sm:mx-16 w-full"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							sx={{ border: "solid 2px black" }}
							className="flex items-center space-x-8 px-16 rounded-full border-1 shadow-0 w-1/2"
						>
							<FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

							<Input
								placeholder="Search by user name, pet name, phone or email"
								className="font-robotoslab flex w-full"
								disableUnderline
								fullWidth
								value={accountSearch}
								inputProps={{
									"aria-label": "Search",
								}}
								onChange={(ev: ChangeEvent<HTMLInputElement>) =>
									setAccountSearch(ev.target.value)
								}
							/>
						</Paper>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className="flex items-center justify-center space-x-8 px-16 shadow-0 w-1/2"
						>
							<Button
								variant="contained"
								className="font-robotoslab flex w-1/2 bg-blue-700 hover:bg-blue-800 text-white"
								onClick={() => searchUsersViewNavigate()}
							>
								Search
							</Button>
						</Paper>
					</motion.div>
				</div>

				{/* Search for verterinary clients */}
				<div className="flex items-start w-full flex-col mt-32">
					<motion.div
						className="flex flex-col items-start min-w-0 mx-8 sm:mx-16"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Typography className="font-medium text-lg" gutterBottom>
							Search for Veterinary Clients
						</Typography>
					</motion.div>

					<motion.div
						className="flex flex-row items-start min-w-0 mx-8 sm:mx-16 w-full"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							sx={{ border: "solid 2px black" }}
							className="flex items-center space-x-8 px-16 rounded-full border-1 shadow-0 w-1/2"
						>
							<FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

							<Input
								placeholder="Search by client name, pet name, phone or address"
								className="flex w-full"
								disableUnderline
								fullWidth
								value={veterinarySearch}
								inputProps={{
									"aria-label": "Search",
								}}
								onChange={(ev: ChangeEvent<HTMLInputElement>) =>
									setVeterinarySearch(ev.target.value)
								}
							/>
						</Paper>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className="flex items-center justify-center space-x-8 px-16 shadow-0 w-1/2"
						>
							<Button
								variant="contained"
								className="flex w-1/2 bg-blue-700 hover:bg-blue-800 text-white"
								
							>
								Search
							</Button>
						</Paper>
					</motion.div>
				</div>

				{/* ISBAT Search */}
				<div className="flex items-start w-full flex-col mt-32">
					<motion.div
						className="flex flex-col items-start min-w-0 mx-8 sm:mx-16"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Typography className="font-medium text-lg" gutterBottom>
						Search Stripe Payment Records
						</Typography>
					</motion.div>

					<motion.div
						className="flex flex-row items-start min-w-0 mx-8 sm:mx-16 w-full"
						initial={{ x: -20 }}
						animate={{ x: 0, transition: { delay: 0.3 } }}
					>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							sx={{ border: "solid 2px black" }}
							className="flex items-center space-x-8 px-16 rounded-full border-1 shadow-0 w-1/2"
						>
							<FuseSvgIcon color="disabled">heroicons-solid:search</FuseSvgIcon>

							<Input
								placeholder="Search by card last 4 digits"
								className="flex w-full"
								disableUnderline
								fullWidth
								value={stripeSearch}
								inputProps={{
									"aria-label": "Search",
								}}
								onChange={(ev: ChangeEvent<HTMLInputElement>) =>
									setStripeSearch(ev.target.value)
								}
							/>
						</Paper>
						<Paper
							component={motion.div}
							initial={{ y: -20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
							className="flex items-center justify-center space-x-8 px-16 shadow-0 w-1/2"
						>
							<Button
								variant="contained"
								className="flex w-1/2 bg-blue-700 hover:bg-blue-800 text-white"
								onClick={() => searchStripeTransactions()}
								disabled={!stripeSearch}
							>
								Search
							</Button>
						</Paper>
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default AdminSearchContentApp;
