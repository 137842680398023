import lazyWithReducer from 'app/store/lazyWithReducer';
import authRoles from '../../auth/authRoles';
import BulkPetUoloadApp from './BulkPetUploadApp';


/**
 * The ProjectBulkPetUploadApp configuration.
 */
const BulkPetUploadAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: 'bulk-pet-upload',
			element: <BulkPetUoloadApp />,
			exact: true
		},
		
	]
};

export default BulkPetUploadAppConfig;
