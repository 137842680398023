import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";

/**
 * FuseDialog component
 * This component renders a material UI ```Dialog``` component
 * with properties pulled from the redux store
 */
function SignoutConfirmationDialog({ callback }) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		callback();
	};

	const handleSignout = () => {
		setOpen(false);

		navigate("/sign-out");
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				
				<Typography className="font-quicksand text-20px font-700 leading-28px text-peeva-black">
					<WarningIcon /> Logging Out
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Typography className="font-quicksand text-16px font-700 leading-28px text-peeva-black">
						You are now being Logged Out
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					size="medium"
					color="secondary"
					className="sm:h-56px h-48px rounded-[32px] bg-white  border-peeva-yellow border-[3px] hover:border-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
					onClick={handleClose}
				>
					Cancel
				</Button>
				<Button
					size="medium"
					className="sm:h-56px h-48px rounded-[32px] bg-white  border-peeva-yellow border-[3px] hover:border-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
					color="warning"
					onClick={handleSignout}
					autoFocus
				>
					Signout
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SignoutConfirmationDialog;
