import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { InferType } from "yup";
import * as yup from "yup";
import _ from "@lodash";
import jwtService from "./../services/jwtService";
import { Alert, Checkbox, FormGroup, Stack } from "@mui/material";
import { useState } from "react";
import HTMLReactParser from "html-react-parser";
import FieldLabel from "../../client/editprofile/components/FieldLabel";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { peevaBlue } from "@fuse/colors";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup
		.string()
		.email("You must enter a valid email")
		.required("You must enter a email"),
});

const defaultValues = {
	email: "",
};

/**
 * The modern forgot password page.
 */
function ForgotPasswordPage() {
	const [showMessage, setShowMessage] = useState(false);
	const [email, setEmail] = useState("");
	const [customError, setCustomError] = useState("");
	const [loading, setLoading] = useState(false);

	const {
		control,
		formState,
		trigger,
		handleSubmit,
		setError,
		clearErrors,
		reset,
	} = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	function onSubmit({ email }: InferType<typeof schema>) {
		setEmail(email);
		setShowMessage(false);

		jwtService
			.forgotPassword(email)
			.then((user) => {
				setShowMessage(true);

				reset(defaultValues);
				// No need to do anything, user data will be set at app/auth/AuthContext
			})
			.catch(
				(
					_errors: {
						type: "email" | "password" | `root.${string}` | "root";
						message: string;
					}[]
				) => {
					setLoading(false);
					setCustomError(_errors[0].message);

					setTimeout(() => {
						setCustomError("");
					}, 8000);
				}
			);
	}

	return (
		<>
			<div className="flex flex-col bg-white justify-center w-full h-full md:overflow-hidden hide-scrollbar">
				{loading && <FuseSplashScreen />}
				<div className="h-full w-full flex md:flex-row flex-col overflow-hidden hide-scrollbar">
					<form
						name="signinForm"
						noValidate
						className="justify-center md:basis-6/12 flex flex-col w-full h-full md:p-[40px_80px_20px_80px] p-[80px_20px_80px_20px] gap-[20px] items-center"
						id="signuphome-form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<img
							className="h-[40px] w-[110.86px]"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
						
						<Typography className="text-4xl font-extrabold leading-tight tracking-tight">
							Forgot password?
						</Typography>
						<div className="mt-2 flex items-baseline font-medium">
							<Typography>Fill the form to reset your password</Typography>
						</div>

						{showMessage && (
							<Typography className="mt-32 txt-6xl">
								<Stack sx={{ width: "100%" }} spacing={2}>
									<Alert variant="filled" severity="success">
										A reset password link has been sent to <b>{email}</b>.
										Please open your email and click on the reset password link
										to set a new password. If you don’t see the email, you may
										need to check your spam folder.
									</Alert>
								</Stack>
							</Typography>
						)}
						<div className="flex flex-col w-full items-center">
						{customError && (
							<Stack className="pb-6" sx={{ width: "100%" }} spacing={2}>
								<Alert variant="filled" severity="error" onClose={() => {}}>
									<p>{HTMLReactParser(customError)}</p>
								</Alert>
							</Stack>
						)}
							<div className="flex flex-col gap-[8px] w-full">
								<FieldLabel>Email</FieldLabel>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] md:h-46px text-14px md:text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											type="email"
											autoFocus
											placeholder="email@provider.com"
											error={!!errors.email}
											helperText={errors?.email?.message}
											required
											fullWidth
											variant="outlined"
											inputProps={{ maxLength: 255 }}
											sx={{ border: "2px solid black" }}
										/>
									)}
								/>
							</div>
						</div>

						<div className="flex">
							<CustomPrimaryButton
								onClick={() => {
									trigger();
								}}
							>
								Send reset link
							</CustomPrimaryButton>
						</div>
						<div className="flex justify-center items-baseline">
							<Typography className="text-peeva-black md:text-16px text-14px font-700 md:leading-22px leading-22px">
								Don’t have an account?
							</Typography>
							<Link
								className="ml-4 text-blue !no-underline md:text-16px text-14px font-700 md:leading-22px leading-22px"
								to="/sign-up"
							>
								Sign up
							</Link>
						</div>
					</form>
					{/* <div className="flex md:h-[100vh] h-full w-[640px] basis-6/12 justify-end">
						<img
							className="md:object-fill !min-w-full !w-full"
							src="assets/img/signupIcon.svg"
						/>
					</div> */}
					<div className="md:max-h-[100vh] flex justify-between flex-col h-full w-full md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 sm:shadow bg-no-repeat bg-top object-fill md:bg-center bg-contain md:bg-cover">
						<img className="object-fill" src="assets/img/signupIcon.svg" />
					</div>
				</div>
			</div>
		</>
	);
}

export default ForgotPasswordPage;
