import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLayoutEffect } from "react";
import petService from "@fuse/services/petService";

const schema = yup.object().shape({
	pet: yup.string().optional(),
});

function AddAlertPetHeaderAfterSelection({ pet }) {
	const defaultValues = {
		pet: "",
	};

	const { control, formState, trigger, handleSubmit, setError } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { isValid, dirtyFields, errors } = formState;

	useLayoutEffect(() => {}, [pet]);



	return (
		<>
		<div className="flex w-full container bg-white">
				<div className="flex flex-col flex-auto leading-20px content-start">
					<Typography className="font-quicksand text-18px md:text-20px font-700 leading-28px">
						Lost Pet Alert
					</Typography>
					<p className="font-robotoslab text-16px font-400 leading-24px mt-16">
					Please go over your pet's details to make sure your pets information is accurate and as descriptive as possible. Please make sure your pet's photo is current.
					</p>
				</div>
			</div>
			{/* <div className="flex w-full container bg-white">
				<div className="flex flex-col sm:flex-row flex-auto min-w-0 p-24 md:p-32 pb-0 md:pb-0 content-start">
					<div className="flex flex-col w-full border-2 rounded-8 p-12 leading-loose items-start">
						<Typography className="text-3xl font-semibold leading-14">
							Lost Pet Alert
						</Typography>
						<p className="font-semibold">
							Here you can report your pet as missing and we will send out a
							Lost Pet Alert with your pet's picture, microchip ID, and your
							contact information to every pet care professional possible within
							50 miles of wherever your pet was last seen (whether they are in
							the Peeva network or not!)
						</p>
						<p className="font-semibold">
							Please review all data in the form before saving.
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-row sm:flex-row flex-auto min-w-0 p-16 pb-0 md:pb-0 content-start">
				<Typography className=" font-semibold leading-14 md:pl-24">
					Please go over your pet's details to make sure your pets information
					is accurate and as descriptive as possible.
				</Typography>
			</div>
			<div className="flex flex-row sm:flex-row flex-auto min-w-0 p-16 pb-0 md:pb-0 content-start">
				<Typography className=" font-semibold leading-14 md:pl-24">
					Please make sure your pet's photo is current.
				</Typography>
			</div>
			<div className="flex flex-row sm:flex-row flex-auto min-w-0 p-16 pb-0 md:pb-0 content-start">
			<Button className="ml-24 !rounded-6" variant="outlined" color="secondary" onClick={() => markAsRead()}>Mas as Found</Button>
			</div> */}
		</>
	);
}

export default AddAlertPetHeaderAfterSelection;
