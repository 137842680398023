import React, { useEffect, useState } from "react";
import { useAppDispatch } from "app/store";
import { Navigate, useNavigate, useParams } from "react-router";
import userService from "@fuse/services/userService";
import { IUserData } from "@fuse/models/user.models";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MobileDetect from "mobile-detect";
import { Card, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Link } from "react-router-dom";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";

function AdminUserSearchResultView() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const searchkey = params.searchkey;
	const [userDataList, setUserDataList] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);

	const md = new MobileDetect(window.navigator.userAgent);
	const isMobile = md.mobile();
	const theme = useTheme();

	const columns = [
		{ id: "ownerName", label: "Owner Name" },
		{ id: "email", label: "Email" },
		{ id: "address", label: "Address" },
		{ id: "phoneNumber", label: "Phone Number" },
		{ id: "petName", label: "Pet Name" },
		{ id: "chipId", label: "Chip ID" },
	];
	useEffect(() => {
		if (searchkey) {
			setLoading(true);
			userService
				.searchUsersByAdmin({
					searchTerm: searchkey,
					pageNumber: 1,
				})
				.then((res: any) => {
					if (res) {
						setUserDataList(res);
					}
					setLoading(false);
				}).catch(err => {
					setLoading(false);
				});
		}

	}, [searchkey]);
	return (
		<>
			{loading && <FuseSplashScreen />}
			<div className="w-full p-[20px]">
				<div className="flex flex-col gap-[20px]">
					<Card className="p-[28px] overflow-hidden">
						<div className="flex flex-wrap w-full items-center px-8 py-12 md:px-16 border-b">
							<div className="flex flex-col flex-auto my-12 mx-8">
								<div className="flex items-center space-x-8">
									<motion.div
										initial={{ x: 20, opacity: 0 }}
										animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
									>
										<Typography
											className="flex items-center sm:mb-12"
											component={Link}
											role="button"
											to="/admin-dashboard"
											color="inherit"
										>
											<FuseSvgIcon size={20}>
												{theme.direction === "ltr"
													? "heroicons-outline:arrow-sm-left"
													: "heroicons-outline:arrow-sm-right"}
											</FuseSvgIcon>
											<Typography className="flex mx-4 font-700 text-2xl">
												User Accounts
											</Typography>
										</Typography>
										<Typography
											className="flex items-center sm:mb-12"
											role="button"
											color="inherit"
										></Typography>
									</motion.div>
								</div>
							</div>
						</div>
						<TableContainer component={Paper} sx={{ maxHeight: 580 }}>
							<Table stickyHeader aria-label="Search results">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell className="font-700 bg-white" key={column.id}>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{userDataList.map((user) => (
										<TableRow key={user.id}>
											{columns.map((column) => (
												<TableCell
													className="font-400 cursor-pointer"
													key={column.id}
													onClick={() => navigate("/admin/pet/" + user.petId)}
												>
													{user[column.id]}
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Card>
				</div>
			</div>
		</>
	);
}

export default AdminUserSearchResultView;
