import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import PetRegisterForm from './PetRegisterForm';
import PetRegisterSubscription from './PetRegisterSubscription';
import PetRegistrationComplete from './PetRegistrationComplete';

const PetRegisterFlowConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: ['customer', 'admin'],
	routes: [
		{
			path: 'pet-register',
			element: <PetRegisterForm />
		},
		{
			path: 'pet-subscription/:id',
			element: <PetRegisterSubscription />
		},
		{
			path: 'pet-registration-complete',
			element: <PetRegistrationComplete />
		}
	]
};

export default PetRegisterFlowConfig;
