import Typography from "@mui/material/Typography";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import { MouseEvent } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AdminSearchContentApp from "./components/AdminSearchContentApp";

/**
 * The crypto dashboard app header.
 */
function AdminSearchAppHeader() {
	const theme = useTheme();

	return (
		<>
			<div className="flex flex-wrap w-full items-center px-8 py-12 md:px-16 border-b">
				<div className="flex flex-col flex-auto my-12 mx-8">
					<div className="flex items-center space-x-8">
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
						>
							<Typography
								className="flex items-center sm:mb-12"
								component={Link}
								role="button"
								to="/admin-dashboard"
								color="inherit"
							>
								<FuseSvgIcon size={20}>
									{theme.direction === "ltr"
										? "heroicons-outline:arrow-sm-left"
										: "heroicons-outline:arrow-sm-right"}
								</FuseSvgIcon>
								<span className="font-robotoslab flex mx-4 font-medium text-xl">
									Sarch Records
								</span>
							</Typography>
						</motion.div>
					</div>
				</div>
			</div>
			<AdminSearchContentApp />
		</>
	);
}

export default AdminSearchAppHeader;
