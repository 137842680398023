import { useEffect, useLayoutEffect, useState } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import { IVetModel } from '@fuse/models';
import vetService from '@fuse/services/vetService';
import { useParams } from 'react-router';

const container = {
	show: {
		transition: {
			staggerChildren: 0.06
		}
	}
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 }
};

/**
 * The analytics dashboard app.
 */
function VetDetailApp() {
	const[vet, setVet] = useState<IVetModel>({} as IVetModel);
	const param = useParams();
	const petId = param.id;
	useLayoutEffect(() => {
		
		
	}, []);
	
	return (
		<FusePageSimple	
			content={
				<motion.div
					className="grid grid-cols-1 sm:grid-cols-2 gap-32 w-full p-24 md:p-32"
					variants={container}
					initial="hidden"
					animate="show"
				>
					<motion.div
						variants={item}
						className="sm:col-span-2 lg:col-span-3"
					>
						
					</motion.div>
					<div className="w-full mt-16">
						<div id="vet_map" className="w-80 h-80">

						</div>
						
					</div>					
				</motion.div>
			}
		/>
	);
}

export default VetDetailApp;
