import { Typography } from "@mui/material";
import { motion } from "framer-motion";

function PetProfilesFooter() {
	return (
		<>
			<motion.div className="px-[50px] mt-[30px] sm:mt-[50px]">
				<div className="flex w-full justify-center items-center pt-16 sm:pt-32">
					<img
						className="my-auto h-30px w-[84px]"
						src="assets/img/checkout-logo.svg"
						alt="logo"
					/>
				</div>
				<div className="flex w-full flex-col justify-center items-center pt-[2em]">
					<Typography className="font-robotoslab flex w-full lg:w-1/3 justify-center text-center font-400 text-16 leading-24px">
						We are dedicated to ending the missing pet epidemic with
						cutting-edge and reliable solutions, catering to the needs of pet
						owners, veterinarians, shelters, and rescues.
					</Typography>
					<Typography className="flex w-full lg:w-1/4 justify-center mt-16 font-robotoslab font-700 text-16 leading-24px">
						640 Ellicott Street Buffalo, NY 14203
					</Typography>
					<Typography className="flex w-full lg:w-1/4 justify-center font-700 text-16 leading-24px underline">
						833-PEEVA-CO / 833-733-8226
					</Typography>
				</div>
				<div className="flex w-full flex-row justify-center items-center pt-[1.5em]">
					<a
						href="https://peeva.co/terms-of-service"
						className="flex w-1/2 sm:w-1/4 justify-center items-center font-robotoslab font-700 text-14 leading-22px"
						role="button"
						target="_blank"
					>
						Terms of Service
					</a>

					<a
						href="https://peeva.co/privacy"
						className="pl-2 flex w-1/2 sm:w-1/4 justify-center items-center font-700 text-14 leading-22px"
						role="button"
						target="_blank"
					>
						Privacy Policy
					</a>
				</div>
				<div className="flex w-full flex-row justify-center items-center pt-[2em]">
					<Typography
						variant="body2"
						gutterBottom
						className="font-robotoslab flex w-full text-peeva-black-120 text-14px sm:text-16px font-400 leading-16px mt-8 items-center justify-center"
					>
						Copyright © {new Date().getFullYear()} Peeva Inc. All rights
						reserved.
					</Typography>
				</div>
			</motion.div>
		</>
	);
}

export default PetProfilesFooter;
