import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useEffect } from 'react';
import _ from '@lodash';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUser } from 'app/store/user/userSlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

/**
 * The ProjectDashboardAppHeader page.
 */
function AdminDashboardAppHeader() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);

	useEffect(() => {
		
	}, [dispatch]);


	function handleEditProfile() {
		navigate('/add-alert');
	}

	return (
		<>
		<div className="flex flex-col w-full px-24 sm:px-32">
			<div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-24">
				<div className="flex flex-auto items-center min-w-0">			
					<div className="flex flex-col min-w-0 mx-16">
						<Typography className="text-lg font-semibold tracking-tight leading-7 md:leading-snug truncate">
							Peeva Admin Tools
						</Typography>						
					</div>
				</div>
				
			</div>
		</div>
		<div className="w-full p-12 pt-16 sm:pt-16 lg:pr-0 lg:pl-0 justify-center">
				<div className="flex justify-center py-24 w-1/2 sm:w-1/3 flex-col mx-auto">
				<Typography variant="h5" component="p" className="text-left">
					I would like to ...
				</Typography>
				<Stack spacing={2} direction="column" className="py-24">
					<Button
						variant="contained"
						className="bg-blue-700 hover:bg-blue-800 text-white font-semibold rounded-8 py-20"
						onClick={() => navigate("/admin-search")}
					>
						Search / Edit Records
					</Button>
					<Button
						variant="contained"
						className="bg-blue-700 hover:bg-blue-800 text-white font-semibold rounded-8 py-20"
						onClick={() => navigate("/admin-sites")}
					>
						View Onboarded Sites
					</Button>
					<Button
						variant="contained"
						className="bg-blue-700 hover:bg-blue-800 text-white font-semibold rounded-8 py-20"
						onClick={() => navigate("/admin-send-email")}
					>
						Send and Email
		 					</Button>
					</Stack>
				</div>
		 		</div> 
		</>

	);
}

export default AdminDashboardAppHeader;
