import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AddVetAppHeader from "./AddVetAppHeader";
import { Card } from "@mui/material";
import AddVetSearch from "./AddVetSearch";
import VetSearchListing from "./VetSearchListing";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function AddVetApp() {
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});
	const onSearch = (val) => {
		if (val) {
			setLoading(true);
		}
		setSearch(val);
	};

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, [search, placesService]);
	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<AddVetAppHeader />
				</Card>
				<Card className="p-[28px]">
					<AddVetSearch onSearchCallBack={onSearch} />
				</Card>
				{loading && <FuseSplashScreen />}
				{placesService && search && (
					<VetSearchListing service={placesService} search={search} />
				)}
			</div>
		</div>
	);
}

export default AddVetApp;
