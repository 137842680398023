import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import App from './app/App';
import ReactDOM from "react-dom/client";

import { datadogRum } from '@datadog/browser-rum';
import TagManager from 'react-gtm-module'
import { datadogLogs } from '@datadog/browser-logs';


window.global = window

var sampleRate = 100;
if (process.env.VITE_APP_DEPLOYMENT_ENV === 'local') {
    sampleRate = 1;
    console.log('Datadog RUM sample rate set to 1%. This should only show when running locally.');
}

datadogRum.init({
    applicationId: 'd5d2a3b6-23a4-45ce-a480-024c6554483a',
    clientToken: 'pub01612ef83beacc708e1c3998e5c59497',
    site: 'datadoghq.com',
    service: 'peeva-frontend-react',
    env: process.env.VITE_APP_DEPLOYMENT_ENV,
    version: process.env.VITE_APP_COMMIT_HASH,
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: sampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ["https://api.fetch-dev.peeva.co", "https://api.fetch-stage.peeva.co", "https://api.fetch.peeva.co", /https?:\/\/(api|auth).*\.peeva\.co/],
});


datadogLogs.init({
    clientToken: 'pub01612ef83beacc708e1c3998e5c59497',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: sampleRate,
    service: 'peeva-frontend-react',
    env: process.env.VITE_APP_DEPLOYMENT_ENV,
    version: process.env.VITE_APP_COMMIT_HASH,
    forwardReports: "all"
}); 


const tagManagerArgs = {
    gtmId: 'GTM-M7KMRG'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
