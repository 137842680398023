function CardHeading({
	heading = '',
	subHeading = '',
	customClassHeading = '',
	customClassSubHeading = '',
}) {
	return (
		<div id="heading-container" className="flex flex-col gap-[12px]">
			{heading && (
				<h1
					className={
						customClassHeading
							? customClassHeading
							: "sm:text-[20px] leading-[28px] font-700 text-peeva-black text-[18px]"
					}
				>
					{heading}
				</h1>
			)}
			{subHeading && (
				<h3
					className={
						customClassSubHeading
							? customClassSubHeading
							: "font-400 sm:text-[16px] sm:leading-[24px] text-peeva-black text-[14px] leading-[17.5px]"
					}
				>
					{subHeading}
				</h3>
			)}
		</div>
	);
}

export default CardHeading;
