import { memo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { IVetModel } from "@fuse/models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from "react-router";

export interface IImageMap {
	placeId: string;
	url: string;
	address?: string;
	phone?: string
}
/**
 * The TeamMembersWidget widget.
 */
function UserVetList({ vets }) {

	const navigate = useNavigate();
	const [imageMap, setImageMap] = useState(Array<IImageMap>);
	const [vetsDetail, setVetsDetail] = useState<any[]>([]);
	const vetsArray = vets as Array<IVetModel>;

	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});

	useEffect(() => {
		if (placesService) {
			vetsArray
				.filter((f) => f.googlePlaceId)
				.forEach((vet) => {
					placesService.getDetails(
						{ placeId: vet.googlePlaceId },
						(placeDetails) => {
							if (placeDetails?.photos?.length > 0) {
								const url = placeDetails.photos[0].getUrl({ maxHeight: 300 });
								if (!imageMap.find((f) => f.placeId == vet.googlePlaceId)) {
									setVetsDetail(old => [...[{ vet: placeDetails, vetImage: url }], ...old])
									setImageMap([
										...imageMap,
										{ placeId: vet.googlePlaceId, url },
									]);
								}
							}
						}
					);
				});
		}
	});
	const handleVetClick = (vet: IVetModel) => {
		try {
			navigate(`/client/vet-info/profile/${vet.id}`, { state: {} });
		} catch (e) {
			console.error(e);
		}
	}

	const getPetImage = (vet: IVetModel) => {
		if (vet?.googlePlaceId) {
			return (
				imageMap.find((f) => f.placeId === vet.googlePlaceId)?.url ||
				"assets/img/logo.png"
			);
		} else {
			return "assets/img/logo.png";
		}
	}

	return (
		<div className="flex gap-[20px] overflow-x-auto overflow-y-hidden">
			{vetsArray.map((vet) => (
				<div className="flex flex-col items-center justify-center gap-[12px] cursor-pointer hover:scale-[101%] sm:w-[91px] w-[75px]" key={vet.id} onClick={() => handleVetClick(vet)}>
					<div className="sm:w-[91px] sm:h-[91px] w-[75px] h-[75px] rounded-[8px] overflow-hidden">
						<img className="w-full h-full object-cover" src={getPetImage(vet)} />
					</div>
					<h3 className="font-700 text-[14px] leading-[19.6px] whitespace-nowrap truncate w-full">{vet.name}</h3>
				</div>
			))}
		</div>
	);
}

export default memo(UserVetList);
