import clsx from "clsx";
import { memo } from "react";
import _ from "@lodash";
import { FuseNavigationType } from "../FuseNavigation/types/FuseNavigationType";
import NavbarToggleButton from "app/theme-layouts/shared-components/NavbarToggleButton";
import HeaderFullScreenToggle from "app/theme-layouts/shared-components/FullScreenToggle";

type FuseShortcutsProps = {
	className?: string;
	navigation: FuseNavigationType;
	onChange: (T: string[]) => void;
	shortcuts?: string[];
	variant?: "horizontal" | "vertical";
};

/**
 * The FuseShortcuts component is responsible for rendering a list of shortcuts based on the navigation and shortcuts props.
 * It uses various MUI components to render the list items and search input.
 * The component is memoized to prevent unnecessary re-renders.
 */
function FuseShortcuts(props: FuseShortcutsProps) {
	const {
		navigation = [],
		shortcuts = [],
		onChange,
		variant = "horizontal",
		className = "",
	} = props;

	return (
		<div
			className={clsx(
				"flex flex-1",
				variant === "vertical" && "shrink grow-0 flex-col",
				className
			)}
		>
			<div
				className={clsx("flex flex-1", variant === "vertical" && "flex-col")}
			>
				<NavbarToggleButton />
				
			</div>
		</div>
	);
}

export default memo(FuseShortcuts);
