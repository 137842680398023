import { useEffect } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'app/store';
import _ from '../../../../@lodash/@lodash';
import AddVetAppHeader from './SendEmailAppHeader';

const container = {
	show: {
		transition: {
			staggerChildren: 0.06
		}
	}
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 }
};

/**
 * The analytics dashboard app.
 */
function SendEmailApp() {
	const dispatch = useAppDispatch();
	
	useEffect(() => {
		
	}, [dispatch]);

	return (
		<FusePageSimple
			header={<AddVetAppHeader />}
			content={
				<motion.div
					className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-32 w-full p-24 md:p-32"
					variants={container}
					initial="hidden"
					animate="show"
				>
					


					<div className="w-full mt-16 sm:col-span-3">
						<Typography className="text-2xl font-semibold tracking-tight leading-6">
							Your Audience
						</Typography>
						<Typography
							className="font-medium tracking-tight"
							color="text.secondary"
						>
							Demographic properties of your users
						</Typography>
					</div>

					<div className="sm:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-32 w-full">
					<Typography
							className="font-medium tracking-tight"
							color="text.secondary"
						>
							Demographic properties of your users
						</Typography>
					</div>
				</motion.div>
			}
		/>
	);
}

export default SendEmailApp;
