import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DeleteConfirmationModal from '../DeleteConfirmationDialog/DeleteConfirmationModal';

interface DeleteButtonProps {
    recordId: number;
    onDelete: (recordId: number) => void;
    buttonText?: string;
    buttonClass?:string;
    dialogText?:string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ recordId, onDelete, buttonText, buttonClass, dialogText }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDelete = () => {
        onDelete(recordId);
        setOpen(false);
    };

    return (
        <>
            <IconButton  className={`${buttonClass}`} aria-label="delete" size="small" color="error" onClick={handleOpen}>
                <DeleteOutlinedIcon className={`mr-3`} /> 
                {buttonText}
            </IconButton>
            <DeleteConfirmationModal open={open} handleClose={handleClose} handleDelete={handleDelete} dialogText={dialogText} />
        </>
    );
};

export default DeleteButton;
