import { IUserDataInfoModel, IUserInfoModel } from "@fuse/models/user.models";
import _ from "../../../../../../@lodash/@lodash";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";

/**
 * The analytics dashboard app.
 */
function PetOwnerApp({ user }) {
	const [userInfo, setUserInfo] = useState<IUserInfoModel>(
		{} as IUserInfoModel
	);
	const currentUser = useSelector(selectUser);

	useEffect(() => {
		setUserInfo(user as IUserInfoModel);
	}, [user]);

	function getuserAddressLine1(data: IUserDataInfoModel) {
		if (data) {
			return data.address1 + (data.address2 ? ", " + data.address2 : "");
		}
		return "";
	}

	function getUserPhoneNumber(data: IUserDataInfoModel) {
		if (data?.phoneNumber) {
			var cleaned = ("" + data.phoneNumber).replace(/\D/g, "");
			var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				return "(" + match[1] + ") " + match[2] + "-" + match[3];
			}
		}
		return "";
	}

	function getUserState(data: IUserDataInfoModel) {
		if (data?.state) {
			return data.state.abbreviation;
		}
		return data?.state;
	}

	return (
		<>
			<div className="flex w-full flex-col">
				<div className="flex flex-col md:flex-row justify-between pb-10 gap-10 md:gap-3">
					<Typography className="font-robotoslab text-18px md:text-22px font-700 leading-21px md:leading-26px text-peeva-black">
						Owner Information
					</Typography>

					<Link to="/client/profile" className="text-peeva-blue">						
						<Typography className="font-robotoslab text-14px md:text-16px font-700 leading-20px md:leading-22px" >
						<AccountCircleIcon className="mr-2" />	Edit Profile
						</Typography>
					</Link>
				</div>

				<div className="flex flex-row py-10">
				<div className="flex flex-col px-10">
					<img
							className="w-[95px] h-[95px] rounded-[8px]"
							src={currentUser.data?.photoUrl || "assets/img/logo.png"}
							alt={currentUser.data?.displayName}
						/>
					</div>
					<div className="flex flex-col">
					<Typography
						className="font-robotoslab text-12px md:text-14px text-700 leading-17px md:leading-20px text-peeva-black capitalize mb-3"
					>
						{userInfo.data?.displayName}
					</Typography>
					<Typography
						className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black mb-3"
					>
						{userInfo.data?.email}
					</Typography>
					<Typography
						className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black capitalize mb-3"
					>
						{getUserPhoneNumber(userInfo.data)}
					</Typography>
					<Typography
						className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black capitalize mb-3"
					>
						{getuserAddressLine1(userInfo.data)}{user.data?.city}, {getUserState(user.data)} {user.data?.zipcode}
					</Typography>
					
					</div>
					
				</div>
			</div>
		</>
	);
}

export default PetOwnerApp;
