import lazyWithReducer from 'app/store/lazyWithReducer';
import authRoles from '../../auth/authRoles';
import SendEmailApp from '../sendemail/SendEmailApp';

const AdminSitesAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: 'admin-sites',
			element: <SendEmailApp />,
			exact: true
		}
	]
};

export default AdminSitesAppConfig;
