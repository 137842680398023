import lazyWithReducer from 'app/store/lazyWithReducer';
import authRoles from '../../auth/authRoles';
import AdminSearchApp from './AdminSearchApp';
import AdminPetSearchResultView from './components/PetSearchView/AdminPetSearchResultView';
import AdminUserSearchResultView from './components/UserSearchView/components/AdminUserSearchResultView';
import StripeSearchResultsView from './components/StripeSearch/StripeSearchResultsView';


const AdminSearchAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: 'admin-search',
			element: <AdminSearchApp />,
			exact: true
		},
		{
			path: 'admin/pet/:id',
			element: <AdminPetSearchResultView />,
			exact: true
		},
		{
			path: 'admin/users/:searchkey',
			element: <AdminUserSearchResultView />,
			exact: true
		},
		{
			path: 'admin/stripe-search/:searchkey',
			element: <StripeSearchResultsView />,
			exact: true
		},
	]
};

export default AdminSearchAppConfig;
