import { useEffect, useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "app/store";
import _ from "@lodash";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { Card, Typography } from "@mui/material";
import AdminSearchAppHeader from "./AdminSearchAppHeader";
import FusePageCarded from "@fuse/core/FusePageCarded";
import AdminSearchContentApp from "./components/AdminSearchContentApp";

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-header": {
		backgroundColor: theme.palette.background.paper,
		borderBottomWidth: 1,
		borderStyle: "solid",
		borderColor: theme.palette.divider,
	},
	"& .FusePageSimple-toolbar": {},
	"& .FusePageSimple-content": {},
	"& .FusePageSimple-sidebarHeader": {},
	"& .FusePageSimple-sidebarContent": {
		backgroundColor: theme.palette.background.default,
	},
}));

/**
 * The CryptoDashboardApp page.
 */
function AdminSearchApp() {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
	const dispatch = useAppDispatch();

	useEffect(() => {}, [dispatch]);

	useEffect(() => {
		setLeftSidebarOpen(!isMobile);
	}, [isMobile]);

	return (
		<>
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<AdminSearchAppHeader />
				</Card>
			</div>		
		</div>
		</>
	);
}

export default AdminSearchApp;
