import lazyWithReducer from 'app/store/lazyWithReducer';
import EditProfileApp from './EditProfileApp';
import { authRoles } from '../../auth';


/**
 * The finance dashboard app config.
 */
const EditProfileAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: "client/profile",
			children: [
				{
					path: '',
					element: <EditProfileApp />,
				}
				
			],
		},
	]
};

export default EditProfileAppConfig;
