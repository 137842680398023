import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BackupContactType } from "../types/TeamMemberType";
import { TextField } from "@mui/material";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FieldLabel from "../../editprofile/components/FieldLabel";

const schema = yup.object().shape({
	id: yup.string().optional(),
	name: yup
		.string()
		.required("Name is required")
		.min(2, "The Name must be at least 2 characters"),

	phoneNumber: yup
		.string()
		.required("Phone Number is required")
		.min(9, "Enter a valid phone number"),
});

function BackupContactDialog({ info, handleClose }) {
	const [contact, setContact] = useState<BackupContactType>(
		{} as BackupContactType
	);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			id: info.id ?? "",
			name: info.name ?? "",
			phoneNumber: info.phoneNumber ?? "",
		},
		resolver: yupResolver(schema),
	});

	const { control, watch, trigger, reset, handleSubmit, formState, setValue } =
		methods;
	const { errors, isValid, dirtyFields } = formState;

	useLayoutEffect(() => {
		if (info) {
			setContact(info);
		}
	}, []);

	function onClose() {
		handleClose({});
	}

	function onSubmit(data: any) {
		handleClose(data);
	}

	return (
		<React.Fragment>
			<Dialog
				open={true}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="min-w-360"
				maxWidth="lg"
			>
				<form
					name="registerForm"
					noValidate
					className="flex w-full flex-col justify-center overflow-y-scroll"
					id="signupform-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<DialogTitle className="min-w-[480px]">
						{contact.id ? "Update" : "Add"} Backup Contact
					</DialogTitle>
					<DialogContent>
						<div className="flex-col w-full gap-32">
							<div className="flex flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
									<FieldLabel>Name</FieldLabel>
									<Controller
										name="name"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="bg-white w-min-[480px] rounded-24 h-[44px] text-14px font-400 leading-22px text-black placeholder-black"
												required
												aria-label="First Name"
												autoFocus
												id="firstName"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.name}
												helperText={errors?.name?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
									<FieldLabel>Phone Number</FieldLabel>
									<Controller
										name="phoneNumber"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												id="phoneNumber"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.phoneNumber}
												helperText={errors?.phoneNumber?.message as string}
											/>
										)}
									/>
								</div>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							color="secondary"
							className="h-42px rounded-[24px] bg-peeva-yellow border hover:bg-peeva-yellow-110 transition-colors duration-200 py-10  font-400 text-14px leading-16px text-peeva-black"
							aria-label="Continue to pet info"
							type="submit"
							size="large"
							onClick={handleClose}
						>
							Close
						</Button>
						<Button
							variant="contained"
							color="secondary"
							className="h-42px rounded-[24px] bg-peeva-yellow border hover:bg-peeva-yellow-110 transition-colors duration-200 py-10  font-400 text-14px leading-16px text-peeva-black"
							aria-label="Continue to pet info"
							type="submit"
							size="large"
							onClick={() => {
								trigger();
							}}
						>
							Save
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
}
export default BackupContactDialog;
