import _ from "../../../../../../@lodash/@lodash";
import AddPetForm from "./AddPetForm";
import FusePageCarded from "@fuse/core/FusePageCarded";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import AddPetHeader from "./AddPetHeader";
import { useEffect, useState } from "react";
import utilityService from "@fuse/services/utilityService";
import petService from "@fuse/services/petService";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router";
import HTMLReactParser from "html-react-parser";
import { Card } from "@mui/material";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function AddPetApp() {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const [allSpecies, setSpecies] = useState([]);
	const [allBrands, setBrands] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [customError, setCustomError] = useState("");
	
	useEffect(() => {

		utilityService
			.getStaticData()
			.then((res: any) => {
				if (res.result) {
					setSpecies(res.result.species);
					setBrands(res.result.brands);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	


	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px]">
					<AddPetForm
						allSpecies={allSpecies}
						allBrands={allBrands}
						loading={loading}
					/>
				</Card>
			</div>
		</div>
		
	);
}

export default AddPetApp;
